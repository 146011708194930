import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;
import dayjs from 'dayjs';

import 'react-datepicker/dist/react-datepicker.css';
import {
  File,
  User,
  UserCheck,
} from "feather-icons-react/build/IconComponents";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import ApexCharts from 'react-apexcharts';
import {
  getAllJobs,
  getPaidPayments,
  getPendingJobs,
  getPendingPayments,
  getAllCounts,
  getMonthlyCounts,
} from "../../service/dashboardSerice";
import ReactApexChart from "react-apexcharts";
import { getAllInventories } from "../../service/inventoryService";

const Dashboard = () => {
  const [pendingJobs, setPendingJobs] = useState(null);
  const [allJobs, setAllJobs] = useState(null);
  const [pendingPayments, setPendingPayments] = useState(null);
  const [paidPayments, setPaidPayments] = useState(null);
  const [counts, setCounts] = useState([]);

  const [monthlySales, setMonthlySales] = useState([]);
  const [monthlyPurchase, setMonthlyPurchase] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [quantity, setQuantity] = useState([])
  const [productName, setProductName] = useState([])
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    dashboardData();
    countData();
    monthlySalesPurchase(selectedYear);
  }, [selectedYear]);

  const countData = async () => {
    try {
      const countResponse = await getAllCounts();
      setCounts(countResponse.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const monthlySalesPurchase = async (year) => {
    try {
      const response = await getMonthlyCounts(year);
      const salesData = Array(12).fill(0); // Initialize array with 12 zeroes
      const purchaseData = Array(12).fill(0); // Initialize array with 12 zeroes

      // Populate the sales and purchase arrays with data from the response
      Object.entries(response.data.sales || {}).forEach(([month, count]) => {
        salesData[month - 1] = count; // month - 1 because array is 0-indexed
      });

      Object.entries(response.data.purchases || {}).forEach(
        ([month, count]) => {
          purchaseData[month - 1] = count; // month - 1 because array is 0-indexed
        }
      );

      setMonthlySales(salesData);
      setMonthlyPurchase(purchaseData);
    } catch (error) {
      console.log(error.message);
    }
  };

  const dashboardData = async () => {
    setPendingJobs(await getPendingJobs());
    setAllJobs(await getAllJobs());
    setPendingPayments(await getPendingPayments());
    setPaidPayments(await getPaidPayments());
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  var donutChart = {
    chart: {
      height: 350,
      type: "donut",
      toolbar: {
        show: false,
      },
    },
    // colors: ['#4361ee', '#888ea8', '#e3e4eb', '#d3d3d3'],
    series: [44, 55, 41, 17],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  // Chart options and data
  const chartOptions = {
    series: [
      {
        name: "Sales",
        data: monthlySales,
      },
      {
        name: "Purchase",
        data: monthlyPurchase,
      },
    ],
    colors: ["#28C76F", "#EA5455"],
    chart: {
      type: "bar",
      height: 350,
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ], // Month names as categories
    },
    yaxis: {
      min: 0,
      max: Math.max(...monthlySales, ...monthlyPurchase) + 5, // Set Y-axis dynamically based on max value
      tickAmount: 5,
    },
    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetY: 0,
          },
        },
      },
    ],
  };

/////////// Start Inventory Chart///////////
  useEffect(() => {
    const getInventories = async () => {
      try {
        const inventoryData = await getAllInventories();
        const currentDate = new Date();


        const nonExpiredData = inventoryData.filter(item => {
          const expireDate = new Date(item.expire_date);
          return expireDate >= currentDate;
        });


        const filteredData = nonExpiredData.filter(item => {
          const createdAt = new Date(item.createdAt);
          return (!startDate || createdAt >= new Date(startDate)) &&
            (!endDate || createdAt <= new Date(endDate));
        });

        const groupedData = filteredData.reduce((acc, item) => {
          const { product_id, stock_qty } = item;
          const productId = product_id._id;
          const productName = product_id.productName;

          if (!acc[productId]) {
            acc[productId] = { stock_qty: 0, productId, productName };
          }

          acc[productId].stock_qty += stock_qty;
          return acc;
        }, {});

        const groupedDataArr = Object.values(groupedData);

        setQuantity(groupedDataArr.map(item => item.stock_qty));
        setProductName(groupedDataArr.map(item => item.productName));
      } catch (error) {
        console.log(error.message);
      }
    };

    getInventories();
  }, [startDate, endDate]);



  const InventoryChart = () => {
    const options = {
      chart: {
        height: 350,
        type: 'bar',
        events: {
          click: function (chart, w, e) {
            console.log(chart, w, e);
          }
        }
      },
      colors: ['#008FFB'],
      plotOptions: {
        bar: {
          columnWidth: '10%',
          distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      tooltip: {
        enabled: true,
        custom: function ({ dataPointIndex }) {
          const qty = quantity[dataPointIndex];
          const name = productName[dataPointIndex]
          return `<div class="arrow_box">
                    <span><strong>${name}</strong></span><br/>
                    <span>Available Quantity: <strong>${qty}</strong></span>
                    
                  </div>`;
        }
      },
      xaxis: {
        categories: productName,
        labels: {
          style: {

            fontSize: '14px',
          }
        }
      }
    };

    const stock = [
      {

        data: quantity
      }
    ];

    return (
      <div>
        <ApexCharts
          options={options}
          series={stock}
          type="bar"
          height={350}
        />
      </div>
    );
  };

  useEffect(() => {
    const getStartAndEndOfMonth = () => {
      const now = new Date();
      const start = new Date(now.getFullYear(), now.getMonth(), 1);
      const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      return { start, end };
    };

    const { start: currentMonthStart, end: currentMonthEnd } = getStartAndEndOfMonth();
    setStartDate(dayjs(currentMonthStart));
    setEndDate(dayjs(currentMonthEnd));
  }, []);

  const handleDateChange = (dates) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  };
/////////End  Inventory chart/////////
    return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-widget w-100">
                <div className="dash-widgetimg">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/dash1.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    <CountUp
                      start={0}
                      end={pendingPayments?.totalInvoiceValue}
                      duration={3}
                      prefix="LKR "
                    />
                  </h5>
                  <h6>Total Outstanding</h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-widget dash1 w-100">
                <div className="dash-widgetimg">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/dash2.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    <CountUp
                      start={0}
                      end={paidPayments?.totalInvoiceValue}
                      duration={3} // Duration in seconds
                      prefix="LKR "
                    />
                  </h5>
                  <h6>Total Paid Amount</h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-widget dash2 w-100">
                <div className="dash-widgetimg">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/dash3.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    <CountUp
                      start={0}
                      end={pendingJobs?.count}
                      duration={3} // Duration in seconds
                    />
                  </h5>
                  <h6>Pending Jobs</h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-widget dash3 w-100">
                <div className="dash-widgetimg">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/dash4.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    <CountUp
                      start={0}
                      end={allJobs?.count}
                      duration={3} // Duration in seconds
                    />
                  </h5>
                  <h6>All Jobs</h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-count">
                <div className="dash-counts">
                  <h4>{counts.customerCount}</h4>
                  <h5>Customers</h5>
                </div>
                <div className="dash-imgs">
                  <User />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das1">
                <div className="dash-counts">
                  <h4>{counts.supplierCount}</h4>
                  <h5>Suppliers</h5>
                </div>
                <div className="dash-imgs">
                  <UserCheck />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das2">
                <div className="dash-counts">
                  <h4>{counts.purchaseCount}</h4>
                  <h5>Purchase</h5>
                </div>
                <div className="dash-imgs">
                  <ImageWithBasePath
                    src="assets/img/icons/file-text-icon-01.svg"
                    className="img-fluid"
                    alt="icon"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das3">
                <div className="dash-counts">
                  <h4>{counts.salesCount}</h4>
                  <h5>Sales</h5>
                </div>
                <div className="dash-imgs">
                  <File />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das2">
                <div className="dash-counts">
                  <h4>{counts.employeeCount}</h4>
                  <h5>Employee</h5>
                </div>
                <div className="dash-imgs">
                  <File />
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das1">
                <div className="dash-counts">
                  <h4>{counts.InvoiceCount}</h4>
                  <h5>Invoice</h5>
                </div>
                <div className="dash-imgs">
                  <File />
                </div>
              </div>
            </div>
          </div>
          {/* Button trigger modal */}

          <div className="row">
            <div className="col-xl-6 col-sm-12 col-12 d-flex">
              <div className="card flex-fill">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="card-title mb-0">Purchase &amp; Sales</h5>
                  <div className="graph-sets">
                    <ul className="mb-0">
                      <li>
                        <span>Sales</span>
                      </li>
                      <li>
                        <span>Purchase</span>
                      </li>
                    </ul>
                    <div className="dropdown dropdown-wraper">
                      <select
                        className="form-select"
                        value={selectedYear}
                        onChange={(e) =>
                        handleYearChange(Number(e.target.value))
                        }
                      >
                        {[
                          new Date().getFullYear(),
                          new Date().getFullYear() - 1,
                          new Date().getFullYear() - 2,
                          new Date().getFullYear() - 3,
                        ].map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {[
                          new Date().getFullYear(),
                          new Date().getFullYear() - 1,
                          new Date().getFullYear() - 2,
                          new Date().getFullYear() - 3,
                        ].map((year) => (
                          <li key={year}>
                            <Link
                              to="#"
                              className="dropdown-item"
                              onClick={() => handleYearChange(year)}
                            >
                              {year}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div id="sales_charts" />
                  <Chart
                    options={chartOptions}
                    series={chartOptions.series}
                    type="bar"
                    height={320}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Donut Chart</h5>
                </div>
                <div className="card-body">

                  <div id="donut-chart" />
                  <ReactApexChart
                    options={donutChart}
                    series={donutChart.series}
                    type="donut"
                    height={350}
                  />
                </div>
              </div>
            </div>
          </div>


          {/* /////// Inventory Chart Dilshan ////////// */}
          <div className="row">
            <div className="col-xl-12">
              <div className="card flex-fill">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="card-title mb-0">Inventory Chart</h5>
                  <div className="graph-sets">
                    <ul className="mb-0">
                      <div style={{ display: 'inline-flex', alignItems: 'center', marginRight: '20px' }}>
                        <span style={{
                          width: '10px',
                          height: '10px',
                          backgroundColor: '#008FFB',
                          borderRadius: '50%',
                          display: 'inline-block',
                          marginRight: '5px'
                        }}></span>
                        <span>Available Stock Level</span>
                      </div>
                    </ul>

                    <div className="dropdown dropdown-wraper">
                      <RangePicker
                        value={[startDate, endDate]}
                        onChange={handleDateChange}
                        defaultValue={[dayjs(startDate), dayjs(endDate)]}
                        format="YYYY-MM-DD"
                      />
                    </div>

                  </div>
                </div>
                <div className="card-body">
                  <div id="sales_charts" />

                  <InventoryChart />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
