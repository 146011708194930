import axios from "axios";

export const getAllInventories = async () => {
    try {
        const response = await axios.get('/inventories'); 
        return response.data;
    } catch (error) {
        console.error('Error fetching inventories:', error);
        throw error;
    }
};