
import axios from "axios";
import Swal from "sweetalert2";
const showWarning = (str) => {
    return Swal.fire({
        title: "Error",
        text: str,
        icon: "error",
    });
}


export const createEmployeeResign = async (employeeId, description, status,setLoading) => {
    try {
        setLoading(true)
      const response = await axios.post("/employee-resign", {
        employee: employeeId, 
        description: description, 
        status: status 
      });
        if (response.data) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: " Resignation Applied Successfully",
            })
        }else{
            showWarning("Error applying resign")
        }
        setLoading(false)

  
      
      console.log('Employee resignation created successfully:', response.data);
      return response.data; 
    } catch (error) {
      console.error('Failed to create employee resignation:', error.response ? error.response.data : error.message);
      throw error; 
    }
  };