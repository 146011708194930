import axios from "axios";
import Swal from "sweetalert2";

export const getAllActiveEmployees = async () => {
    try {
        const response = await axios.get("/employees-active");
        return response.data
    } catch (error) {
        console.log(error)
        showWarning('Failed to fetch allowance');
    }
}

const showWarning = (str) => {
    return Swal.fire({
        title: "Error",
        text: str,
        icon: "error",
    });
}