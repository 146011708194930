
import React, { useEffect, useState } from "react";
import "./style.css";
import { Table, TimePicker,Spin } from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { getEmployeeRecordsByDate,updateAttendanceTime } from "../../../service/adminAttendanceService";

const EditAttendanceAdminModal = ({ handleCancel, employeeId, date ,onChange,isView}) => {
  const [data, setData] = useState([]);
  const [recordTimes, setRecordTimes] = useState({});
  const [loading,setLoading]=useState(false)
  const fetchRecords = async () => {
    try {
      const data = await getEmployeeRecordsByDate(employeeId, date);
      const initialTimes = {};
      data.forEach((record) => {
        initialTimes[record._id] = dayjs(record.time, 'HH:mm:ss'); // Ensure you're using the correct ID here
      });
      setRecordTimes(initialTimes);
      setData(data);
    } catch (err) {
      console.log(err);
    }
  };
 const handleReset=()=>{
    fetchRecords()
 }
  const handleChangeTime = (time, rowId) => {
    setRecordTimes((prev) => ({
      ...prev,
      [rowId]: time,
    }));
  };

  useEffect(() => {
    if (employeeId && date) {
      fetchRecords();
    }
  }, [employeeId, date]);

  

  const handleSubmit = async(recordId) => {
    const recordTime=recordTimes[recordId].format('HH:mm:ss');
    const attendanceId=recordId
    try {
        const updatedAttendance = await updateAttendanceTime(attendanceId, recordTime,setLoading);
        console.log('Successfully updated:', updatedAttendance);
        onChange()
      } catch (error) {
        console.error('Failed to update attendance');
      }
  };




  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (date) => {
        const formattedDate = new Date(date).toISOString().split('T')[0];
        return formattedDate;
      },
    },
    {
      title: 'Time',
      dataIndex: 'time',
      render: (_, record) => (
        <div>
          {!isView&&(
        <TimePicker
          disabled={isView}
          value={recordTimes[record._id] || null}
          className="input-group-text"
          onChange={(time) => handleChangeTime(time, record._id)} // Pass _id to handleChangeTime
          format="HH:mm"
          style={{ width: '207px',
           }}
        />
          )}
          {isView &&(recordTimes[record._id].format('HH:mm:ss'))}
        </div>
      ),
    },
    {
      title: 'Actions',
      render: (text, record) => (
        <div>
          {!isView &&(
          <button className="btn btn-submit me-2" onClick={() => handleSubmit(record._id)}>{loading ? <Spin size="small" /> : "Save"}</button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="modal-content">
      <div className="modal-header border-0 custom-modal-header">
        <div className="page-title">
          {!isView &&(<h4>Edit Attendances</h4>)}
          {isView && (<h4>Attendances</h4>)}
          <p className="mb-0">Manage Your Attendances</p>
        </div>
      </div>
      <div className="modal-body custom-modal-body">
        <div className="row mb-3">
          <div className="col-12">
            <div className="input-blocks">
              <label>
                <strong>Employee : </strong>
                {data.length > 0 ? `${data[0].employee.first_name} ${data[0].employee.last_name}` : 'N/A'}
              </label>
            </div>
          </div>
        </div>
        <Table
          dataSource={data}
          columns={columns}
          pagination={false}
          rowKey="_id" // Ensure this matches your record ID
        />
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: '20px' }}>
          <div></div>
          <div>
            {!isView&&(<button className="btn btn-reset me-2"
            onClick={handleReset}>Reset</button>)}
            <button
              type="button"
              className="btn btn-cancel me-2"
              onClick={handleCancel}
            >
              Close
            </button>
            
          </div>
        </div>
      </div>
    </div>
  );
};

EditAttendanceAdminModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  employeeId: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onChange:PropTypes.func.isRequired,
  isView: PropTypes.bool.isRequired
};

export default EditAttendanceAdminModal;
