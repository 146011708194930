import React, { useEffect, useState } from "react";
import { createLeaveEmployee } from "../../../service/leavesEmployeeApiService";
import { getAllLeaveTypes } from "../../../service/leaveApiServices/leaveTypesApi";

import Select from "react-select";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import { DatePicker } from "antd";
//const { RangePicker } = DatePicker;

const AddLeaveEmployee = ({ handleCancel,leaveBalances, FetchLeaveEmployees }) => {
  const leaveDayOptions = [
    { value: "Choose Status", label: "Choose Status" },
    { value: "Full Day", label: "Full Day", dayValue: 1 },
    { value: "Half Day", label: "Half Day", dayValue: 0.5 },
  ];

//  const [selectedDate, setSelectedDate] = useState([null, null]);
  const [dateRange, setDateRange] = useState([]);

  const [selectedStartDate,setSelectedStartDate] = useState(null);
  const [selectedEndDate,setSelectedEndDate] = useState(null);

  const [selectedLeaveType, setSelectedLeaveType] = useState(null);
  const [reason, setReason] = useState();
  const [leaveDays, setLeaveDays] = useState([]);
  const [leaveType, setLeaveType] = useState([]); //[funeral, sick leave]

  // reset form fields after success message
const resetForm = () => {
  setSelectedStartDate(null);
  setSelectedEndDate(null);
  setDateRange([]);
  setSelectedLeaveType(null)
  setReason("");
  setLeaveDays([]);


};
  useEffect(() => {
    fetchLeaveTypes();
    
  }, []);

  const fetchLeaveTypes = async () => {
    try {
      const leaveTypeData = await getAllLeaveTypes({
        search: "",
        setLoading: () => {}, 
      });
      if (leaveTypeData) {
        const options = leaveTypeData.map((leave) => ({
          id: leave._id,
          label: leave.name,
          leaveCount: leave.minimumLeaveCount,
        }));

        setLeaveType([{ value: "Choose", label: "Choose" }, ...options]);
      }
    } catch (error) {
      console.error("Error fetching leave Types:", error);
    }
  };


  // Handle Date Change
  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
//Handle Dates
const handleStartDateChange = (startDate) => {
  setSelectedStartDate(startDate);

};


//Handle validation
const handleEndDateChange = async(endDate) =>{
  setSelectedEndDate(endDate);
  if (selectedStartDate && endDate){
    const start = selectedStartDate.$d;
    const end = endDate.$d;

    // Log the extracted dates to ensure correctness
    console.log("Start Date:", start);
    console.log("End Date:", end);

  // Calculate the difference in time (in milliseconds)
  const durationInMs = end - start;

  // Convert the duration from milliseconds to days
  const durationInDays = Math.ceil(durationInMs / (1000 * 60 * 60 * 24));

  console.log("Duration in days:", durationInDays);
 
  console.log("Fetched leave employees:", FetchLeaveEmployees);
  if (selectedLeaveType) {
    const leaveCount = selectedLeaveType.leaveCount;
    
    // Check if the selected date range is less than the minimum leave count
    if (durationInDays < leaveCount) {
        //alert(`The selected date range must be at least ${leaveCount} days.`);
        Swal.fire({
          icon:'warning',
          title: 'Error',
          text: `The selected date range must be at least ${leaveCount} days.`,
          confirmButtonText: 'OK'
        })
        return; 
    }
    
    // Find  leave balance
    const leaveBalance = leaveBalances.find(leave => leave.leaveType._id === selectedLeaveType.id);

    if (leaveBalance) {
        console.log("Matching leave balance found:", leaveBalance);
        
        if (leaveBalance.leaveCount < durationInDays) {
          Swal.fire({
            icon: 'warning',
            title: 'Insufficient Leave Balance',
            text: `You only have ${leaveBalance.leaveCount} days left.`,
            confirmButtonText: 'OK'
        });
            return; 
        }
    } else {
        //console.log("No matching leave balance found for the selected leave type.");
        Swal.fire({
          icon: 'error',
          title: 'No Matching Leave Balance',
          text: 'No matching leave balance found for the selected leave type.',
          confirmButtonText: 'OK'
      });
        return;
    }
  }
      const daysArray = [];
      let dayCount = 1;
      let currentDate = new Date(start);
      while (currentDate.getTime() <= end.getTime()) {
        daysArray.push({
          day: `Day ${dayCount}`,
          date: formatDate(currentDate),
          leaveType: null,
        });
        currentDate.setDate(currentDate.getDate() + 1);
        dayCount++;
      }
      setDateRange(daysArray);
      setLeaveDays(daysArray);
    } else {
      setDateRange([]);
      setLeaveDays([]);
      console.log("");
    }
}

const disabledEndDate = (currentDate) => {
  if (!selectedStartDate) {
    return false; // If no start date is selected, no date is disabled
  }

  const endOfCurrentMonth = selectedStartDate.clone().endOf('month');

  // Disable dates before the selected start date and after the current month
  return (
    currentDate < selectedStartDate.startOf('day') || 
    currentDate > endOfCurrentMonth
  );
};
  // Handle Leave Type Change
  const handleLeaveTypeChange = (index, selectedOption) => {
    
    const updatedLeaveDays = [...leaveDays];
    updatedLeaveDays[index].leaveType = selectedOption.label;
    updatedLeaveDays[index].dayValue = selectedOption.dayValue;
    setLeaveDays(updatedLeaveDays);
  };

  const handleLeaveTypeSelection = (selectedOption) => {
    setSelectedLeaveType(selectedOption); 
    //console.log("Selected Leave Type:", selectedOption); 
   // console.log("Leave balances:",leaveBalances)

// Check if the selected leave type ID exists in the leaveBalances
const matchingLeave = leaveBalances.find(leave => leave.leaveType._id === selectedOption.id);

if (matchingLeave) {
    console.log(`Matching leave found:`, matchingLeave._id);
    
} else {
    console.log("No matching leave balance found for the selected leave type.");
}

  };

  // Calculate total leave days (sum of Full Day and Half Day values)
  const calculateTotalLeaveDays = () => {
    return leaveDays.reduce((total, day) => total + (day.dayValue || 0), 0); // Default to 0 if dayValue is not set
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const user = JSON.parse(localStorage.getItem("user"));
    

    if (!user || !user.data._id || !user.data.name) {
      console.error("User data is missing from local storage.");
      Swal.fire({
        icon: "warning",
        title: "Error",
        text: "User data is missing from local storage. Please log in again.",
      });
      return;
    }

    const totalLeaveDays = calculateTotalLeaveDays();
    if (selectedLeaveType && totalLeaveDays < selectedLeaveType.leaveCount) {
    
      Swal.fire({
        icon: "warning",
        title: "Invalid leave Days",
        text: `The total leave days must be at least ${selectedLeaveType.leaveCount}. Currently selected: ${totalLeaveDays}`,
      });
      return;
    }
    if (!reason || reason.trim() === "") {
      alert("Reason is required.");
      return;
    }


    const startDateFormatted = formatDate(selectedStartDate.$d);
  const endDateFormatted = formatDate(selectedEndDate.$d);

    // Prepare the leave data
    
    const leaveData = {
      
      leavetype: selectedLeaveType.id,
      leaveTypeName: selectedLeaveType.label,
      leaveDays: leaveDays.map((day) => ({
        date: day.date,
        Day: day.leaveType, // Full Day or Half Day
      })),
      reason: reason,
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      approval: null,
      userId: user.data._id, // Add userId and name from local storage
      name: user.data.name,
    };
    
    createLeaveEmployee(leaveData)
      .then((response) => {
        console.log("Response from server:", response);
    
        // Check if the server responded with a success status
        if (response.status === "success") {
            FetchLeaveEmployees(); 
          Swal.fire({
            icon: "success",
            title: "Leave Submitted",
            text: "Leave has been submitted successfully!",
          }).then(() => {
            resetForm();
            handleCancel(); 
          });
        } else {
          // Handle non-success statuses
          Swal.fire({
            icon: "error",
            title: "Error",
            text:"Failed to submit leave:" || response.message,
          });
        }
      })
      .catch((error) => {
        console.error("Failed to submit leave:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      });
    
  };

  return (
    <div>
      {/* Add Leave */}

      <div className="page-wrapper-new p-0">
        <div className="content">
          <div className="modal-header border-0 custom-modal-header">
            <div className="page-title">
              <h4>Apply Leave</h4>
            </div>
          </div>
          <div className="modal-body custom-modal-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                

                <div className="col-lg-12">
                  <div className="input-blocks">
                  <label className="form-label">Start Date</label>
                    <div className="input-groupicon calender-input">
                      <DatePicker
                        value={selectedStartDate}
                        onChange={handleStartDateChange}
                        className="filterdatepicker"
                        dateFormat="dd-MM-yyyy"
                        placeholder={"Start Date"}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                      />
                    </div>
                  </div>
                  <div className="input-blocks">
                  <label className="form-label">End Date</label>
                    <div className="input-groupicon calender-input">
                      <DatePicker
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        className="filterdatepicker"
                        dateFormat="dd-MM-yyyy"
                        placeholder={"End Date"}
                        disabledDate={disabledEndDate}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                      />
                    </div>
                  </div>
                </div>


                {dateRange.map((item, index) => (
                  <div key={index} className="col-lg-12">
                    <div className="apply-leave">
                      <div className="leave-apply">
                        <div className="leave-date">
                          <span>{item.day}</span>
                          <p>{item.date}</p>
                        </div>
                        <div className="leave-time">
                          <div className="input-blocks mb-0">
                            <Select
                              className="select"
                              options={leaveDayOptions}
                              placeholder="Select Leave Day"
                              onChange={(selectedOption) =>
                                handleLeaveTypeChange(index, selectedOption)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="col-lg-12">
                  <div className="mb-3">
                    <label className="form-label">Select Leave Type</label>

                    <Select
                      className="select"
                      value={selectedLeaveType}
                      options={leaveType}
                      placeholder="Select Leave Day"
                      onChange={handleLeaveTypeSelection}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="mb-3 summer-description-box mb-0">
                    <label>Reason</label>

                    <input
                      type="text"
                      value={reason}
                      className="form-control"
                      onChange={(event) => setReason(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer-btn">
                <button
                  type="button"
                  className="btn btn-cancel me-2"
                  onClick={() => {
                    resetForm(); // Reset the form
                    handleCancel(); // Close the modal
                  }}
                >
                  Cancel
                </button>

                <button className="btn btn-submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* /Add Leave */}
    </div>
  );
};

AddLeaveEmployee.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  leaveBalances: PropTypes.array.isRequired,
  FetchLeaveEmployees:PropTypes.func.isRequired,
};
export default AddLeaveEmployee;
