import React, { useState } from 'react';
import { Spin } from 'antd';
import Swal from 'sweetalert2';
import { DownloadOutlined } from '@ant-design/icons';
import PropTypes from "prop-types";
import { deductionBulkUpload } from '../../service/deductionService';

const DeductionBulkUpload = ({ handleCancel }) => {
    const [loading, setLoading] = useState(false); // Loading state for upload button
    const [file, setFile] = useState(null); // State to hold selected file

    // Handle file selection
    const handleFileChange = (event) => {
        setFile(event.target.files[0]); // Set selected file
    };

    // Download sample file function
    const downloadSampleFile = () => {
        console.log('Download sample file');
    };

    // Reset form or selected file
    const handleReset = () => {
        setFile(null); // Clear file
    };

    // Handle Upload Function
    const handleUpload = async () => {
        if (!file) {
            Swal.fire({
                title: "Error",
                text: "Please select a file before uploading",
                icon: "error",
            });
            return;
        }

        setLoading(true);
        try {
            await deductionBulkUpload(file); // Pass the file to the bulk upload function
            Swal.fire({
                title: "Success",
                text: "File uploaded successfully",
                icon: "success",
            });
            handleCancel(); // Close the modal on success
        } catch (error) {
            console.error("Error uploading file:", error);
            Swal.fire({
                title: "Error",
                text: "Failed to upload file",
                icon: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="modal-content">
            <div className="page-wrapper-new p-0">
                <div className="content">
                    <div className="modal-header border-0 custom-modal-header">
                        <div className="page-title">
                            <h4>Bulk Upload</h4>
                            <p className="mb-0">Select a file to upload</p>
                        </div>
                    </div>

                    <div className="modal-body custom-modal-body">
                        <div className="content">
                            <div style={{ fontStyle: "italic" }}>*Required field labels</div>
                            <p className="mt-1" style={{ textAlign: "justify" }}>
                                The correct column order is (record_number, employee_id, amount, description, paymentPeriod, installement, paymentType, startDate, endDate, is_active)
                            </p>
                        </div>
                        <div className="mt-3" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div className="mb-3 input-blocks" style={{ flex: 1, marginRight: "20px" }}>
                                <label className="form-label">File Upload</label>
                                <input
                                    type="file"
                                    className="form-control mt-0"
                                    onChange={handleFileChange}
                                    accept=".csv"
                                />
                            </div>
                            <div className="mb-3 input-blocks">
                                <label className="form-label pl-0">Sample File</label>
                                <button className="btn pe-6 download-btn" onClick={downloadSampleFile}>
                                    Download <DownloadOutlined />
                                </button>
                            </div>
                        </div>
                        <div className="pt-3" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <button type="button" className="btn btn-cancel" onClick={handleCancel}>
                                Close
                            </button>
                            <div>
                                <button type="button" className="btn btn-cancel me-2" onClick={handleReset}>
                                    Reset
                                </button>
                                <button type="button" className="btn btn-submit ml-0" onClick={handleUpload} disabled={loading}>
                                    {loading ? <Spin size="small" /> : "Upload"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

DeductionBulkUpload.propTypes = {
    handleCancel: PropTypes.func.isRequired,
};

export default DeductionBulkUpload;
