import React, { useEffect, useState } from 'react';
import { Modal, TimePicker, DatePicker, Select, Badge, Spin, Popconfirm, Table, InputNumber} from 'antd';
import { PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { createAttendanceMethod, deleteAdminAttendance, getAllAdmintAttendancesMethod, getAllEmployeesWithoutPaginationMethod, getAllShiftsMethod,getAllAdmintAttendancesWithoutPagination } from '../../service/ApiServices';
import Swal from 'sweetalert2';
import BulkUploadModal from '../../core/modals/hrm/bulkupload-admin.jsx';
import { API_BASE_URL } from "../../../src/environment";

//pdf generte import start
import jsPDF from 'jspdf';
//import html2canvas from 'html2canvas';
//import jsPDF from 'jspdf';
import 'jspdf-autotable';
//pdf generte import end
//import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
const { RangePicker: DateRangePicker } = DatePicker;
const { RangePicker } = TimePicker;
import okilogo from "../../assets/OKI-logo.png"
import EditAttendanceAdminModal from '../../core/modals/hrm/editAttendanceNew.jsx';
const Designation = () => {





    const startOfCurrentMonth = dayjs().startOf('month');
    const endOfCurrentMonth = dayjs().endOf('month');
    const [selectedRange, setSelectedRange] = useState([
        startOfCurrentMonth,
        endOfCurrentMonth
    ]);
    const [timeRangeForFetch, setTimeRangeForFetch] = useState(null)
    const [hours, setHours] = useState(null);
    const [isDelete, setIsDelete] = useState(false);
    const [minutes, setMinutes] = useState(null);
    const [workingHours, setWorkingHours] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isView, setIsView] = useState(false);
    const [timeRange, setTimeRange] = useState(null);
    const [date, setDate] = useState(null);
    const [employee, setEmployee] = useState(null);
    const [shift, setShift] = useState(null)
    const [search, setSearch] = useState('');
    const [employees, setEmployees] = useState([])
    const [shifts, setShifts] = useState([])
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [success, setSuccess] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isBulkUploadModalOpen, setIsBulkUploadModalOpen] = useState(false);
    const [shouldReloadData, setShouldReloadData] = useState(false);
    const [id,setId]=useState('');
    const [recordDate, setRecordDate] = useState(null);
    const [recordEmpId, setRecordEmpId] = useState(null);
    const [attendanceData, setAttendanceData] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0
    });
const [pdfData, setPdfData] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0
    });
    const [hasFetched, setHasFetched] = useState(false);


    const handleReloadData = () => {
        setShouldReloadData(!shouldReloadData);
    };
//Function generate to excel




const showWarning = (str) => {
    return Swal.fire({
      title: "Error",
      text: str,
      icon: "error",
    });
  }

const handleExcelExport = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Attendance Report');

    // Define cell styles with borders
    const borderStyle = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };

    // Define columns and add header
    worksheet.columns = [
        { header: 'Emp Name', key: 'empName', width: 20 },
        { header: 'Emp Id', key: 'empId', width: 15 },
        { header: 'Date', key: 'date', width: 15 },
        { header: 'Clock In', key: 'clockIn', width: 15 },
        { header: 'Clock Out', key: 'clockOut', width: 15 },
        { header: 'Total Hours', key: 'totalHours', width: 15 }
    ];

    // Add data rows
    pdfData.data.forEach(({ employee, date, clockIn,clockOut,workedHours }) => {
        worksheet.addRow({
            empName: `${employee.first_name} ${employee.last_name}`,
            empId: employee.employee_id,
            date: new Date(date).toLocaleDateString(),
            clockIn: clockIn ? clockIn : 'N/A',
            clockOut: clockOut ? clockOut : 'N/A',
            totalHours: workedHours
        });
    });

    // Apply border styles to all cells
    worksheet.eachRow({ includeEmpty: true }, (row) => {
        row.eachCell({ includeEmpty: true }, (cell) => {
            cell.border = borderStyle;
        });
    });

    // Save the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'attendance_report.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
    });
};

useEffect(() => {
    if (selectedRange[0] && selectedRange[1] && !hasFetched) {
        getAllAdmintAttendancesWithoutPagination({
            setPdfData,
            setLoading: setLoading3,
            params: { page: pdfData.page, limit: pdfData.limit },
            search,
            startDate: selectedRange[0].format("YYYY-MM-DD"),
            endDate: selectedRange[1].format("YYYY-MM-DD"),
            workingHours: timeRangeForFetch ? timeRangeForFetch : "undefined"
        });
        setHasFetched(true)
        console.log(loading3)
    }

    }),[selectedRange[0],selectedRange[1]]


const handlePdfExport = async () => {

    const startdate= selectedRange[0].format('YYYY-MM-DD');
    const enddate=selectedRange[1].format('YYYY-MM-DD');
    const img=okilogo

    // Filter out unwanted columns

    const doc = new jsPDF();
    doc.setTextColor(0,0,0); // Blue color
    doc.setFontSize(16);
    doc.text('OKI International', 15, 13);



    // Larger font size

    doc.setFontSize(14);
    doc.setTextColor(138, 132, 125);
    doc.text(`Attendance Report`, 15, 23);
   doc.text(`Date : ${startdate} - ${enddate}`, 15, 30);
   doc.addImage(img, 'PNG', 170, 8, 22, 25);
    doc.autoTable({
        head: [['Emp Name', 'Emp Id', 'Date','Clock In', 'Clock Out', 'Total Hours']],

        body: pdfData.data.map(({ employee, date, clockIn,clockOut,workedHours }) => [
            [`${employee.first_name} ${employee.last_name}`],
            [employee.employee_id],
            [new Date(date).toLocaleDateString()],
            [ clockIn ? clockIn : 'N/A'],
            [clockOut ? clockOut: 'N/A'],
            [workedHours]

        ]),
        startY: 35,
        theme: 'striped',
        headStyles: {
            fillColor: [138, 132, 125],
        }
    });

    setTimeout(() => {
        doc.save('attendance_report.pdf');
    }, 1000);
    // // Save the PDF
    // doc.save('attendance_report.pdf');
};



////Function to generate PDF end



const storedUserData = localStorage.getItem("user");
const userData = JSON.parse(storedUserData);
const id1 = userData?.data?.uid; //
const [userRightssub, setUserRightssub] = useState([]);
useEffect(() => {
  const fetchUserRightssub = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/userright/${id1}`);
      if (!response.ok) {
        throw new Error("Failed to fetch user rights");
      }
      const data = await response.json();
      console.log("Fetched User Sub Data:", data.data);
      setUserRightssub(data.data || []); // Ensure it's always an array
    } catch (error) {
      console.error("Error fetching data:", error);
      setUserRightssub([]); // Set to empty array in case of error
    }
  };

  fetchUserRightssub(); // Fetch the data on component mount
}, [id]);
const hasJobRightWithAdd = userRightssub.some(right => right.right === 'attendance-employee' && right.adds === 1);
const hasJobRightWithedit = userRightssub.some(right => right.right === 'attendance-employee' && right.updates === 1);
// const hasJobRightWithview = userRightssub.some(right => right.right === 'attendance-employee' && right.viewes === 1);
const hasJobRightWithedel = userRightssub.some(right => right.right === 'attendance-employee' && right.deletes=== 1);
const hasJobRightWithupload = userRightssub.some(right => right.right === 'Shifts' && right.fupload=== 1);













    useEffect(() => {
        const initialCalls = async () => {
            getAllEmployeesWithoutPaginationMethod({ setEmployeeData: setEmployees })
            getAllShiftsMethod({ setShiftData: setShifts })
        }
        initialCalls()
    }, [shouldReloadData])

    const fetchData=()=>{
      try{  getAllAdmintAttendancesMethod({
            setAttendanceData,
            setLoading: setLoading2,
            params: { page: attendanceData.page, limit: attendanceData.limit },
            search,
            startDate: selectedRange[0].format("YYYY-MM-DD"),
            endDate: selectedRange[1].format("YYYY-MM-DD"),
            workingHours: timeRangeForFetch ? timeRangeForFetch : "undefined"
        });}
        catch(err){
            console.log(err)
        }
    }
    useEffect(() => {
        if (success) {
            handleCancel();
            setId(null)
            showSuccess("Successfully saved attendance!")
            getAllAdmintAttendancesMethod({
                setAttendanceData,
                setLoading: setLoading2,
                params: { page: attendanceData.page, limit: attendanceData.limit },
                search,
                startDate: selectedRange[0].format("YYYY-MM-DD"),
                endDate: selectedRange[1].format("YYYY-MM-DD"),
                workingHours: timeRangeForFetch ? timeRangeForFetch : "undefined"
            });
        }
        if (isDelete) {
            showSuccess("Successfully deleted attendance!")
            getAllAdmintAttendancesMethod({
                setAttendanceData,
                setLoading: setLoading2,
                params: { page: attendanceData.page, limit: attendanceData.limit },
                search,
                startDate: selectedRange[0].format("YYYY-MM-DD"),
                endDate: selectedRange[1].format("YYYY-MM-DD"),
                workingHours: timeRangeForFetch ? timeRangeForFetch : "undefined"
            });
        }
    }, [success, isDelete,shouldReloadData])


    useEffect(() => {
        const handler = setTimeout(async () => {

            getAllAdmintAttendancesMethod({
                setAttendanceData,
                setLoading: setLoading2,
                params: { page: attendanceData.page ,limit: attendanceData.limit },
                search,
                startDate: selectedRange[0].format("YYYY-MM-DD"),
                endDate: selectedRange[1].format("YYYY-MM-DD"),
                workingHours: timeRangeForFetch ? timeRangeForFetch : "undefined"
            });
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [search, selectedRange, timeRangeForFetch,shouldReloadData]);

    const handleOk = () => {
        if (!timeRange || !date || !employee) {
            showWarning("Please fill all fields")
            return;
        }
        const formattedTimeRange = timeRange.map(time => dayjs(time).format('HH:mm:ss'));
        const submitData = {
            employee: employee,
            timeRange: formattedTimeRange,
            workingHours,
            date,
            shift: shift,
            id
        }
        createAttendanceMethod({
            data: submitData,
            setLoading,
            setSuccess
        })
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setIsEdit(false);
        resetFields();
    };

    const resetFields = () => {
        setTimeRange(null);
        setDate(null);
        setEmployee("");
        setWorkingHours(null);
        setShift("")
    };

    const handleTimeRangeChange = (value) => {
        setTimeRange(value);
        if (value) {
            const [start, end] = value;
            const duration = dayjs(end).diff(dayjs(start), 'minute');
            const hours = Math.floor(duration / 60);
            const minutes = duration % 60;
            setWorkingHours(`${hours}h ${minutes}m`);
        } else {
            setWorkingHours(null);
        }
    };

    const handleDateChange = (value) => {
        setDate(value ? dayjs(value).format('YYYY-MM-DD') : null);
    };

    const handleEmployeeChange = (value) => {
        setEmployee(value);
    };

    const handleShiftChange = (value) => {
        setShift(value);
    };

    const showSuccess = (str) => {
        return Swal.fire({
            title: "Good job!",
            text: str,
            icon: "success"
        });
    }

    const columns = [
        {
            title: 'Emp Name',
            key: 'name',
            render: (text, record) => `${record.employee.first_name} ${record.employee.last_name}`,
        },
        {
            title: 'Emp Id',
            dataIndex: ['employee', 'employee_id'],
            key: 'employee.employee_id',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => `${new Date(record.date).toLocaleDateString()}`,
        },
        {
            title: 'Clock In',
            dataIndex: 'clockIn',
            key: 'clockIn',
        },
        {
            title: 'Clock Out',
            dataIndex: 'clockOut',
            key: 'clockOut',

        },
        {
            title: 'Total Hours',
            dataIndex: 'workedHours',
            key: 'workingHours',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <td className="action-table-data">
                          <Link className="me-2 p-2" onClick={() => {
                               setIsView(true)
                               setIsEditModalOpen(true)
                               setRecordEmpId(record.employee._id)
                               setRecordDate(record.date)
                               setId(record._id)
                            }}>
                                <i data-feather="download" className="feather-eye"></i>
                            </Link> 
                         {hasJobRightWithedit && ( 
                             <Link className="me-2 p-2" onClick={() => {
                               setRecordEmpId(record.employee._id)
                               setRecordDate(record.date)
                               setId(record._id)
                               setIsEditModalOpen(true)
                            }}>
                                <i data-feather="download" className="feather-edit"></i>
                            </Link>                         
                          )} 
                        {hasJobRightWithedel  && (
                             <Popconfirm
                             title="Delete the Attendance"
                             description="Are you sure to disable this Attendance?"
                             onConfirm={() => {
                                 deleteAdminAttendance({
                                     id: record._id,
                                     setIsDelete
                                 })
                             }}
                             onCancel={() => {
                                 console.log("c")
                             }}
                             okText="Yes"
                             cancelText="No"
                         >
                             <Link className="confirm-text p-2">
                                 <i data-feather="x-circle" className="feather-x-circle"></i>
                             </Link>
                             </Popconfirm>
                         )}
                </td>
            )
        }
    ];
//C:\Users\sdils\Desktop\POS new\pos-ui\src\feature-module\hrm\attendanceadmin.jsx
    const handleTableChange = (pagination) => {
        getAllAdmintAttendancesMethod({
            setAttendanceData,
            setLoading: setLoading2,
            params: { page: pagination.current, limit: pagination.pageSize },
            search,
            startDate: selectedRange[0].format("YYYY-MM-DD"),
            endDate: selectedRange[1].format("YYYY-MM-DD"),
            workingHours: timeRangeForFetch ? timeRangeForFetch : "undefined"
        });
    };

    const onChange = (dates) => {
        setHasFetched(false)
        if (dates === null) {
            setSelectedRange([startOfCurrentMonth, endOfCurrentMonth])
            setHasFetched(false)
            return
        }
        setSelectedRange(dates);
    };

    const handleHoursChange = (value) => {
        setHours(value);
    };

    const handleMinutesChange = (value) => {
        setMinutes(value);
    };

    const formatWorkHours = () => {
        if (hours !== null && minutes !== null) {
            return `${hours}h ${minutes}m`;
        }
        return null;
    };

    useEffect(() => {
        setTimeRangeForFetch(formatWorkHours())
    }, [minutes, hours])

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Attendance</h4>
                            <h6>Manage your Attendances</h6>
                        </div>
                    </div>
                    <div className="page-btn">
                          {!hasJobRightWithupload && (

                             <a className="btn btn-added" onClick={() => setIsBulkUploadModalOpen(true)}>
                             {/* <PlusCircle className="me-2" /> */}
                             Bulk upload
                         </a>
                         )}




                    </div>
                    <div className="page-btn">

                    {!hasJobRightWithAdd && (

                        <a className="btn btn-added" onClick={() => setIsModalOpen(true)}>
                        <PlusCircle className="me-2" />
                        Add New Attendance
                            </a>
                    )}


                    </div>
                </div>

                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="search"
                                        className="form-control"
                                        placeholder="Search"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <Link className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </Link>
                                </div>
                                <DateRangePicker onChange={onChange} value={selectedRange} format="YYYY-MM-DD" />
                                <div style={{ marginLeft: "5px", border: "1px solid #D7D7D7", borderRadius: "5px" }}>
                                    <div >
                                        <InputNumber
                                            min={0}
                                            style={{
                                                width: 90,
                                            }}
                                            disabled={loading2}
                                            max={12}
                                            placeholder="Hours"
                                            value={hours}
                                            onChange={handleHoursChange}
                                            variant="borderless"
                                        />
                                        <span>h </span>
                                        <InputNumber
                                            variant="borderless"
                                            min={0}
                                            max={59}
                                            style={{
                                                width: 90,
                                            }}
                                            disabled={loading2}
                                            placeholder="Minutes"
                                            value={minutes}
                                            onChange={handleMinutesChange}
                                        />
                                        <span style={{ marginRight: "5px" }} >m </span>
                                    </div>
                                </div>
                                <div className="wordset">
                                    <ul>
                                        <li>
                                            <a  data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"
                                            //</li> onClick={generatePDF}
                                                    onClick={handlePdfExport}
                                            >
                                                <img src="assets/img/icons/pdf.svg" alt="pdf" />
                                            </a>
                                        </li>
                                        <li>
                                            <a  data-bs-toggle="tooltip" data-bs-placement="top" title="excel"
                                            onClick={handleExcelExport}>
                                                <img src="assets/img/icons/excel.svg" alt="excel" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="print">
                                                <img src="assets/img/icons/printer.svg" alt="print" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Table
                                id="table-to-pdf"
                                columns={columns}
                                dataSource={attendanceData.data}
                                rowKey="_id"
                                pagination={{
                                    current: attendanceData.page,
                                    pageSize: attendanceData.limit,
                                    total: attendanceData.total
                                }}
                                loading={loading2}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                zIndex={200000}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
                closable={false}
            >
                <div className="modal-content">
                    <div className="page-wrapper-new p-0">
                        <div className="content">
                            <div className="modal-header border-0 custom-modal-header">
                                <div className="page-title">
                                    <h4>{isEdit ? "Edit" : "Add"} Attendance</h4>
                                    <p className="mb-0">Fill in the required fields to {isEdit ? "edit" : "create"} an Attendance</p>
                                </div>
                            </div>
                            <div className="modal-body custom-modal-body">
                                <div className="mb-3">
                                    <label className="form-label">Time Range (Clockin & Clockout)</label>
                                    <RangePicker
                                        size="large"
                                        value={timeRange}
                                        onChange={handleTimeRangeChange}
                                        format="HH:mm:ss"
                                        style={{ width: '100%' }}

                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Date (Clockin & Clockout)</label>
                                    <DatePicker
                                        size="large"
                                        onChange={handleDateChange}
                                        format="YYYY-MM-DD"
                                        value={date ? dayjs(date, 'YYYY-MM-DD') : null}
                                        style={{ width: '100%' }}
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Select Employee</label>
                                    <Select
                                        size="large"
                                        showSearch
                                        placeholder="Select an employee"
                                        onChange={handleEmployeeChange}
                                        value={employees?.find(option => option.value === employee)}
                                        options={employees}
                                        style={{ width: '100%' }}

                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Select Shift</label>
                                    <Select
                                        size="large"
                                        showSearch
                                        placeholder="Select a Shift"
                                        onChange={handleShiftChange}
                                        value={shifts?.find(option => option.value === shift)}
                                        options={shifts}
                                        style={{ width: '100%' }}
                                    />
                                </div>

                                {workingHours && (
                                    <div className="mb-3">
                                        <Badge color='geekblue' text={`Working Hours: ${workingHours}`} />
                                    </div>
                                )}
                                <div className="" style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}>
                                    <button
                                        type="button"
                                        className="btn btn-cancel"
                                        onClick={() => {
                                            handleCancel();
                                        }}
                                    >
                                        Close
                                    </button>
                                    <div className="">
                                        <button
                                            type="button"
                                            className="btn btn-reset me-2"
                                            onClick={resetFields}
                                        >
                                            Reset
                                        </button>
                                        <button type='button' className="btn btn-submit ml-0" onClick={handleOk} disabled={loading}>
                                            {loading ? <Spin size='small' /> : "Save"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal
        zIndex={1050}
        open={isEditModalOpen}
        onOk={() => setIsEditModalOpen(false)}
        onCancel={() => setIsEditModalOpen(false)}
        footer={null}
        maskClosable={false}
        closable={false}
         width={600}
      >
        <EditAttendanceAdminModal
          handleCancel={() => {
            setIsEditModalOpen(false)
            setIsView(false)
        }}
          employeeId={recordEmpId}
          date={recordDate}
          onChange={fetchData}
          isView={isView}
        />
      </Modal>

            <BulkUploadModal
            isVisible={isBulkUploadModalOpen}
            handleCancel={() => setIsBulkUploadModalOpen(false)}
            onUploadData={handleReloadData}
            />
        </div>
    );
};

export default Designation;


