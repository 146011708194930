
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PlusCircle,  Sliders } from 'feather-icons-react/build/IconComponents';
//import Select from 'react-select';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
//import { Tooltip } from 'antd';
import{Table}  from 'antd'
import AddShift from '../../core/modals/hrm/addshift.jsx';
import EditShift from '../../core/modals/hrm/editshift.jsx';
import { API_BASE_URL } from "../../../src/environment";
import moment from 'moment';
import { Popover } from 'antd';
const Shift = () => {
    const [shifts, setShifts] = useState([]);
    const [selectedShiftId, setSelectedShiftId] = useState(null);
    const [shouldReloadData, setShouldReloadData] = useState(false);
    const [search, setSearch] = useState('');
    const [loading,setLoading]=useState(false)

    const fetchShifts = async (search = '') => {
        try {
            setLoading(true)
            const response = await fetch(`http://localhost:8000/api/shifts?search=${encodeURIComponent(search)}`);
            if (!response.ok) {
                throw new Error("Failed to fetch shifts");
            }
            const data = await response.json();
            setShifts(data.data.shifts);
            setLoading(false)
            //console.log(shifts,"SHIFTsss") // Update the state with filtered data
        } catch (error) {
            console.error("Error fetching shifts:", error);
        }
    };


    const storedUserData = localStorage.getItem("user");
    const userData = JSON.parse(storedUserData);
    const id = userData?.data?.uid; //
    const [userRightssub, setUserRightssub] = useState([]);
    useEffect(() => {
      const fetchUserRightssub = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/userright/${id}`);
          if (!response.ok) {
            throw new Error("Failed to fetch user rights");
          }
          const data = await response.json();
          console.log("Fetched User Sub Data:", data.data);
          setUserRightssub(data.data || []); // Ensure it's always an array
        } catch (error) {
          console.error("Error fetching data:", error);
          setUserRightssub([]); // Set to empty array in case of error
        }
      };
    
      fetchUserRightssub(); // Fetch the data on component mount
    }, [id]);
    const hasJobRightWithAdd = userRightssub.some(right => right.right === 'Shifts' && right.adds === 1);
   const hasJobRightWithedit = userRightssub.some(right => right.right === 'Shifts' && right.updates === 1);
   // const hasJobRightWithview = userRightssub.some(right => right.right === 'Shifts' && right.viewes === 1);  
    const hasJobRightWithedel = userRightssub.some(right => right.right === 'Shifts' && right.deletes=== 1); 
   // const hasJobRightWithdown = userRightssub.some(right => right.right === 'Shifts' && right.fdownload=== 1); 
  
    useEffect(() => {
        fetchShifts(search); // Pass search term to fetchShifts
    }, [search, shouldReloadData]); // Ensure shouldReloadData is included to reload on change

    const handleReloadData = () => {
        setShouldReloadData(!shouldReloadData);
    };

    const columns = [
        {
            title: "Shift Name",
            dataIndex: "shiftname",
            sorter: (a, b) => a.shiftname.length - b.shiftname.length,
        },
        {
            title: "Time",
            dataIndex: "time",
            sorter: (a, b) => a.time.length - b.time.length,
        },
        {
            title: "Week Off",
            dataIndex: "weekoff",
            sorter: (a, b) => a.weekoff.length - b.weekoff.length,
        },  {
            title: "Half Day",
            dataIndex: "halfDay",
            sorter: (a, b) => a.halfDay.length - b.halfDay.length,
            render: (text, record) => (
                <Popover content={record.halfDayTime} trigger="hover">
                    <span >{text || "-"}</span>
                </Popover>
            ),
            // render: (text, record) => (
            //     <span>
            //         {text} {record.halfDayTime ? `(${record.halfDayTime})` : ''}
            //     </span>
            // ),
        },
        // {
        //     title: "Half Day Time",
        //     dataIndex: "halfDayTime",
        //     sorter: (a, b) => a.halfDayTime.length - b.halfDayTime.length,
        // },
        {
            title: "Created On",
            dataIndex: "createdon",
            sorter: (a, b) => a.createdon.length - b.createdon.length,
        },
        {
            title: "Status",
            dataIndex: "status",
            sorter: (a, b) => a.status.length - b.status.length,
        },
        {
            title: "Actions",
            dataIndex: "actions",
            sorter: (a, b) => a.createdby.length - b.createdby.length,
        },
    ];

    const MySwal = withReactContent(Swal);
    const showConfirmationAlert = (shiftId) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            showCancelButton: true,
            confirmButtonColor: '#00ff00',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonColor: '#ff0000',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteShift(shiftId);
                MySwal.fire({
                    title: 'Deleted!',
                    text: 'Your file has been deleted.',
                    className: "btn btn-success",
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                });
            } else {
                MySwal.close();
            }
        });
    };

    const deleteShift = async (shiftId) => {
        try {
            const response = await fetch(`http://localhost:8000/api/shifts/${shiftId}`, {
                method: "DELETE",
            });
            if (response.ok) {
                handleReloadData();
                console.log("Shift deleted successfully");
            } else {
                console.error("Failed to delete shift");
            }
        } catch (error) {
            console.error("Error deleting shift:", error);
        }
    };

    const handleLinkClick = async (shiftId) => {
        setSelectedShiftId(shiftId);
    };

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };
    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Shift</h4>
                                <h6>Manage your employees shift</h6>
                            </div>
                        </div>
                      
                        <div className="page-btn">
                        {!hasJobRightWithAdd && (
                               <Link
                               to="#"
                               className="btn btn-added"
                               data-bs-toggle="modal"
                               data-bs-target="#add-units"
                                 >
                               <PlusCircle className="me-2" />
                               Add New Shift
                                 </Link>
                             )} 
                           
                        </div>
                    </div>
                    {/* /product list */}
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                            <div className="search-set">
                            <div className="search-input">
                             
                             {/*                  search              */}
                              <input
                                // type="text"
                                type="search"
                                placeholder="Search"
                                className="form-control form-control-sm formsearch"
                                value={search}
                                onChange={handleSearchChange}
                                // onChange={(e) => setSearch(e.target.value)}
                              />
                              
                              <Link to className="btn btn-searchset">
                                <i data-feather="search" className="feather-search" />
                              </Link>
                            </div>
                            <div className='wordset'>
                            {/* <ul>
                                        <li>
                                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="pdf">
                                               <img src="assets/img/icons/pdf.svg" alt="pdf" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="excel">
                                               <img src="assets/img/icons/excel.svg" alt="excel" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="print">
                                                <img src="assets/img/icons/printer.svg" alt="print" />
                                            </a>
                                        </li>
                                    </ul> */}
                            </div>
                          </div>
                               
                          <div className="search-set">
                                <a href="#" className="btn btn-filter" id="filter_search">
                                    <Sliders className="me-2" />Filter
                                </a>
                            </div>
                            </div>
                        
                            <div className="table-responsive">
                                <Table 
                                    loading={loading}
                                    columns={columns} 
                                    dataSource={shifts.map((shift) => ({
                                        key: shift._id,
                                        shiftname: shift.shiftInfo.shiftName,
                                        time: `${moment(shift.shiftInfo.from, 'HH:mm:ss').format('HH:mm')} - ${moment(shift.shiftInfo.to, 'HH:mm:ss').format('HH:mm')}`,
                                        weekoff: shift.shiftInfo.weekOff,
                                        ...(shift.shiftInfo.halfDay && {
                                            halfDay: shift.shiftInfo.halfDay.day,
                                            halfDayTime:`${moment(shift.shiftInfo.halfDay.from, 'HH:mm:ss').format('HH:mm')} - ${moment(shift.shiftInfo.halfDay.to, 'HH:mm:ss').format('HH:mm')}`,
                                        }),
                                        
                                        createdon: shift.createdAt.split('T')[0],
                                        status: (
                                            <span className={`badge ${shift.shiftInfo.status? 'badge-linesuccess' : 'badge-linedanger'}`} style={{ width:80, padding:6}}>
                                                <Link to="#" style={{ cursor: 'default', fontSize:14}}> {shift.shiftInfo.status ? 'Active' : 'Inactive'}</Link>
                                            </span>
                                        ),
                                        actions: (
                                            <td className="action-table-data">
                                                 {hasJobRightWithedit && (
                                                   <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                   data-bs-toggle="modal"
                                                   data-bs-target="#edit-units"
                                                   onClick={() => handleLinkClick(shift._id)}
                                                    >
                                                        <i data-feather="download" className="feather-edit"></i>
                                                    </Link>
                                                )}
                                                {hasJobRightWithedel && (
                                                   <Link 
                                                   className="confirm-text me-2 p-2" 
                                                   to="#"
                                                   onClick={() => showConfirmationAlert(shift._id)}
                                                    >
                                                        <i
                                                            data-feather="trash-2"
                                                            className="feather-trash-2"
                                                        ></i>
                                                    </Link>
                                                )}
 
                                            </td>
                                        ),
                                    }))} 
                                />
                            </div>
                        </div>
                    </div>
                    {/* /product list */}
                </div>
            </div>
            <AddShift onShiftAdded={handleReloadData}/>
            <EditShift onShiftAdded={handleReloadData} shiftId={selectedShiftId}/>
        </div>
    )
}

export default Shift
