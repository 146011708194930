import React, { useState } from "react";
import "./style.css";
import PropTypes from "prop-types";
import Select from "react-select";
import { createEmployeeResign } from '../../../service/employeeResignService';
import { Spin } from "antd";
import Swal from "sweetalert2";
const EmployeeResignModal = ({ handleCancel, employeeId, empRecord }) => {

    const [selectedStatus, setSelectedStatus] = useState(null)
    const [reason, setReason] = useState('')
    const [loading,setLoading]=useState(false)

    const statusOptions = [
        { value: "Clearance Pending", label: "Clearance Pending" },
        { value: "Payment Pending", label: "Payment Pending" },
        { value: "Resigned", label: "Resigned" }
    ]
    const handleReset = () => {
        setSelectedStatus(null)
        setReason("")
    } 
    const showWarning = (str) => {
        return Swal.fire({
            title: "Error",
            text: str,
            icon: "error",
        });
    }
       
    const handleSubmit = async () => {
       if(selectedStatus===null){
         showWarning("Fill in the required fields to apply ")
         return
        }
        const description = reason;
        const status = selectedStatus.value;
      
        
        try {
          const result = await createEmployeeResign(employeeId, description, status,setLoading);
          console.log(result);
          handleReset();
        } catch (error) {
          
          console.error('Error during submission:', error);
        }
      };
    return (
        <div className="modal-content">
            <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                    <h4>Apply for Resignation</h4>
                    <p className="mb-0">Fill in the required fields to apply </p>
                </div>
            </div>
            <div className="modal-body custom-modal-body">
                <form>
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="input-blocks">
                                <label htmlFor="leaveTypeName">Name</label>
                                <input
                                    type="text"
                                    id="leaveTypeName"
                                    className="form-control"
                                    aria-label="leave Type Name"
                                    value={`${empRecord.first_name} ${empRecord.last_name}`}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="input-blocks">
                                <label htmlFor="leaveTypeName">Status</label>
                                <Select
                                    value={selectedStatus}
                                    options={statusOptions}
                                    className="select"
                                    placeholder="Select the status"
                                    onChange={setSelectedStatus}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="input-blocks">
                                <label htmlFor="leaveTypeName">Reason</label>
                                <textarea
                                    value={reason || ''}
                                    id="leaveDescription"
                                    className="form-control"
                                    aria-label="Leave Description"
                                    rows="4"
                                    placeholder="Enter the reason"
                                    onChange={(e) => setReason(e.target.value)}
                                ></textarea>
                            </div>
                        </div>

                    </div>
                </form>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={() => {
                            handleCancel()
                            handleReset()
                        }}
                    >
                        Close
                    </button>
                    <div>
                        <button
                            type="button"
                            className="btn btn-reset me-2"
                            onClick={handleReset}
                        >
                            Reset
                        </button>
                        <button className="btn btn-submit"
                        onClick={handleSubmit}>
                           {loading ? <Spin size="small" /> : "Apply"}
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
};
EmployeeResignModal.propTypes = {
    handleCancel: PropTypes.func.isRequired,
    employeeId: PropTypes.string.isRequired,
    empRecord: PropTypes.object.isRequired,
};
export default EmployeeResignModal;
