import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./style.css";
import Swal from "sweetalert2";
import { Spin, Button, Table } from "antd";
import { getAllEmployeesWithoutPaginationMethod } from '../../../service/ApiServices';
import { getAllLeaveTypes, getLeaveTypeById} from "../../../service/leaveApiServices/leaveTypesApi";
import {saveLeaveAllocations} from"../../../service/leaveAllocationService"
import Select from 'react-select';
import { CloseOutlined  } from '@ant-design/icons'; 


const CreateLeaveTypeModal = ({
  handleCancel,
  selectedLeaveType,
  handleLeaveTypeAdding
}) => {

  const [leaveCount, setLeaveCount] = useState("");
  const [loading, setLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [leaveTypeOptions, setleaveTypeOptions] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedLeaveTypeOption, setSelectedLeaveTypeOption] = useState(null);
  const [addedRows, setAddedRows] = useState([]); // Array to store added rows
  const [leaveAllocations,setLeaveAllocations]=useState([])
  
  useEffect(() => {
    const fetchEmployees = async () => {
      setLoading(true);
      try {
        const response = await getAllEmployeesWithoutPaginationMethod({
          setEmployeeData
        });
        console.log(response);
      } catch (error) {
        console.error('Error fetching employees:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchEmployees();
  }, []);

  
  useEffect(() => {
    if (employeeData && employeeData.length > 0) {
      console.log('Employee Data:', employeeData);
    }
  }, [employeeData]);

  
  useEffect(() => {
    const fetchLeaveTypes = async () => {
      try {
        const response = await getAllLeaveTypes({ search: '', setLoading });
        console.log('API Response:', response);
        const options = response.map(item => ({
          value: item._id,
          label: item.name
        }));
        setleaveTypeOptions(options);
      } catch (error) {
        console.error("Error in fetching Service Names", error);
        
      } finally {
        setLoading(false);
      }
    };
    fetchLeaveTypes();
  }, [setLoading]);



  const handleAddToTable = async () => {
    if (!selectedEmployee || !selectedLeaveTypeOption || leaveCount === "") {
      Swal.fire("Error", "Please fill in all fields", "error");
      return;
    }
  
    try {
      const leaveType = await getLeaveTypeById(selectedLeaveTypeOption.value);
  
      if (leaveCount > leaveType.quota) {
        Swal.fire("Error", `Leave count exceeds the quota of ${leaveType.quota}`, "error");
        return;
      }
  
      // Create new row data
      const newRow = {
        employeeName: selectedEmployee.label,
        leaveType: selectedLeaveTypeOption.label,
        leaveCount: leaveCount,
      };
  
      const obj = {
        employee: selectedEmployee.value,
        leaveBalances: [{ 
          leaveType: selectedLeaveTypeOption.value,
          leaveCount: leaveCount,
        }],
        finacialYear: currentYear.toString(),
      };
  
      // Update state
      setLeaveAllocations([...leaveAllocations, obj]);
      setAddedRows([...addedRows, newRow]);
      handleReset(); // Reset form after adding
    } catch (error) {
      console.error("Error fetching leave type or adding to table:", error);
      Swal.fire("Error", "Failed to fetch leave type details or add data", "error");
    }
  };
  
  useEffect(() => {
    console.log("ALLOCATION DATA", leaveAllocations);
  }, [leaveAllocations]);
  

// Handle Remove Row
const handleRemoveRow = (index) => {
    const updatedRows = addedRows.filter((row, i) => i !== index);
    setAddedRows(updatedRows);
  
    const updatedLeaveAllocations = leaveAllocations.filter((_, i) => i !== index);
    setLeaveAllocations(updatedLeaveAllocations);
  };
  


  // Handle Reset Form
  const handleSaveClick = async () => {
    try {
      const savedData = await saveLeaveAllocations(leaveAllocations, setLoading);
      console.log('Allocations saved:', savedData);
      
      
      setLeaveAllocations([]); 
      setAddedRows([]);
      handleLeaveTypeAdding();
    } catch (error) {
      console.error('Save failed:', error.message);
      
      
    }
  };
 
  const currentYear = new Date().getFullYear(); 
  const editSaveClick = async () => {
    try {
      const leaveType = await getLeaveTypeById(selectedLeaveTypeOption.value);
  
      if (leaveCount > leaveType.quota) {
        Swal.fire("Error", `Leave count exceeds the quota of ${leaveType.quota}`, "error");
        return;
      }
  
      const obj = {
        employee: selectedEmployee.value,
        leaveBalances: [{ // Update to match new model
          leaveType: selectedLeaveTypeOption.value,
          leaveCount: leaveCount,
        }],
        finacialYear: currentYear.toString(),
         // Include financial year
      };
  
      const updatedLeaveAllocations = [...leaveAllocations, obj];
  
      const savedData = await saveLeaveAllocations(updatedLeaveAllocations, setLoading);
      
      console.log('Allocations saved:', savedData);
  
      handleLeaveTypeAdding();
      setLeaveAllocations([]); 
      setSelectedEmployee(null);
      setSelectedLeaveTypeOption(null);
      setLeaveCount("");
    } catch (error) {
      console.error('Save failed:', error.message);
    }
  };
  
 
  const handleReset = () => {
    setSelectedEmployee(null);
    setSelectedLeaveTypeOption(null);
    setLeaveCount("");
  };
    const handleClose = () => {
        setLeaveAllocations([]);
        setAddedRows([]);
        handleCancel();
        handleReset();
  }


  useEffect(() => {
    if (selectedLeaveType) {
      console.log("Selected Leave Type:", selectedLeaveType);
      console.log("Employee:", {
        value: selectedLeaveType.employee._id,
        label: `${selectedLeaveType.employee.first_name} ${selectedLeaveType.employee.last_name}`,
      });
      console.log("Leave Type Option:", {
        value: selectedLeaveType.leaveType._id,
        label: selectedLeaveType.leaveType.name,
      });
      console.log("Leave Count:", selectedLeaveType.leaveCount);
  
      setSelectedEmployee({
        value: selectedLeaveType.employee._id,
        label: `${selectedLeaveType.employee.first_name} ${selectedLeaveType.employee.last_name}`,
      });
      setSelectedLeaveTypeOption({
        value: selectedLeaveType.leaveType._id,
        label: selectedLeaveType.leaveType.name,
      });
      setLeaveCount(selectedLeaveType.leaveCount);
    }
  }, [selectedLeaveType]);
  
  
  // Ant Design Table Columns
  const columns = [
    {
      title: 'Employee Name',
      dataIndex: 'employeeName',
      key: 'employeeName',
    },
    {
      title: 'Leave Type',
      dataIndex: 'leaveType',
      key: 'leaveType',
    },
    {
      title: 'Leave Count',
      dataIndex: 'leaveCount',
      key: 'leaveCount',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record, index) => (
        <Button
          type="link"
          icon={<CloseOutlined  style={{ color: 'red' , fontSize: '12px'}} />}
          onClick={() => handleRemoveRow(index)}
        />
      ),
    },
  ];

  return (
    <div className="modal-content">
      <div className="modal-header border-0 custom-modal-header">
        <div className="page-title">
          <h4>{selectedLeaveType ? "Edit Allocate Leaves" : "Allocate Leave "}</h4>
          <p className="mb-0">Fill in the required fields to Create </p>
        </div>
      </div>
      <div className="modal-body custom-modal-body">
        <form >
          <div className="row mb-3">
            <div className="col-12">
              <div className="input-blocks">
                <label>Employee</label>
                <Select
                  className="select"
                  options={employeeData}
                  placeholder="Select an employee"
                  value={selectedEmployee}
                  onChange={setSelectedEmployee}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="input-blocks">
                <label>Leave Type</label>
                <Select
                  className="select"
                  options={leaveTypeOptions}
                  placeholder="Select a leave type"
                  value={selectedLeaveTypeOption}
                  onChange={setSelectedLeaveTypeOption}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="input-blocks">
                <label>Leave Count</label>
                <input
                  type="number"
                  className="form-control"
                  value={leaveCount}
                  onChange={(e) => setLeaveCount(e.target.value)}
                />
              </div>
                          {!selectedLeaveType && (
                              <button
                                  type="button"
                                  className="btn btn-reset"
                                  style={{
                                      width: "25%",
                                      marginLeft: "auto",
                                      display: "block",
                                  }}
                                  onClick={handleAddToTable}
                              >
                                  Add
                              </button>
                          )}

            </div>
          </div>
        </form>

        {/* Display the Table */}
        {addedRows.length > 0 && (
        <Table
          columns={columns}
          dataSource={addedRows}
          rowKey={(record, index) => index}
          pagination={{ pageSize: 5 }}
        />
      )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <button
            type="button"
            className="btn btn-cancel"
            onClick={handleClose}
          >
            Close
          </button>
          <div>
            <button
              type="button"
              className="btn btn-reset me-2"
              onClick={handleReset}
            >
              Reset
            </button>
            <button className="btn btn-submit" 
            onClick={selectedLeaveType ? editSaveClick : handleSaveClick}
            >
              {loading ? <Spin size="small" /> : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateLeaveTypeModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleLeaveTypeAdding: PropTypes.func.isRequired,
  selectedLeaveType: PropTypes.object,
};

export default CreateLeaveTypeModal;

