import React, { useState,useRef } from 'react'

import Select from 'react-select'
import dayjs from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TimePicker } from 'antd';
import moment from 'moment';
import { createShift } from '../../../service/ApiServices';
import PropTypes from "prop-types";
import Swal from 'sweetalert2';

dayjs.extend(customParseFormat);

const AddShift = ({onShiftAdded}) => {
    const [shiftName, setShiftName] = useState('');
    const [from, setFromTime] = useState(moment('00:00:00', 'HH:mm:ss'));
    const [to, setToTime] = useState(moment('00:00:00', 'HH:mm:ss'));
    const [weekOff, setWeekOffOption] = useState(null);//value sent for backend
    const [selectedOption, setSelectedOption] = useState(null);
    const [status, statusIsChecked] = useState(false);
    const [halfDay, setHalfDay] = useState(null);
    const [halfDayFrom, setHalfDayFrom] = useState(moment('00:00:00', 'HH:mm:ss'));
    const [halfDayTo, setHalfDayTo] = useState(moment('00:00:00', 'HH:mm:ss'));
    const selectRef = useRef(null);

    const [weekdaysDefinition, setWeekdaysDefinition] = useState([
        { day: 'Monday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
        { day: 'Tuesday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
        { day: 'Wednesday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
        { day: 'Thursday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
        { day: 'Friday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
        { day: 'Saturday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
        { day: 'Sunday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } }
    ]);
    


    // const handleDayCheckboxChange = (index) => {
    //     const updatedWeekdays = [...weekdaysDefinition];
    //     updatedWeekdays[index].checked = !updatedWeekdays[index].checked;
    //     setWeekdaysDefinition(updatedWeekdays);
    // };


    const handleChangeFromTime = (time) => {
        setFromTime(time);
    };

    const handleChangeToTime = (time) => {
        setToTime(time);
    };

    const handleChangeHalfDayFromTime = (time) => {
        if (time) {
            setHalfDayFrom(time);
        }
    };

    const handleChangeHalfDayToTime = (time) => {
        if(time){
            setHalfDayTo(time);
        }
    };

    const handleChange = (weekOff) => {
        setSelectedOption(weekOff);
        setWeekOffOption(weekOff.value);
    };

    const handleStatusCheckboxChange = (event) => {
        statusIsChecked(event.target.checked);
    };


    const handleSubmit = async () => {
        if (!shiftName||!weekOff||from.isSame(moment('00:00:00', 'HH:mm:ss')) || to.isSame(moment('00:00:00', 'HH:mm:ss'))||(halfDay && (
            halfDayFrom.isSame(moment('00:00:00', 'HH:mm:ss')) || halfDayTo.isSame(moment('00:00:00', 'HH:mm:ss'))
          )) ) {
            showWarning("Please fill in the required fields.")
            return
        }
     
        const shift = {
            shiftInfo: {
                shiftName,
                from: from.format('HH:mm:ss'),
                to: to.format('HH:mm:ss'),
                weekOff,
                status,
                weekdaysDefinition,
                ...(halfDay && {
                    halfDay: {
                        day: halfDay,
                        from: halfDayFrom ? halfDayFrom.format('HH:mm:ss') : null,
                        to: halfDayTo ? halfDayTo.format('HH:mm:ss') : null
                    }
                })
            }
        };

        
        try {
            console.log(shift,"trying to create shift");
            const response = await createShift(shift);
            if(response.status==="success"){
                showSuccess()
                resetForm()
            }else{
                const war="Faild to Save Shift";
                showWarning(war)
            }
            
            // Call the callback to update the shift list or UI
            onShiftAdded();
            
            // Close the modal after successful creation
            document.querySelector('[data-bs-dismiss="modal"]').click(); // This assumes you're using Bootstrap modals
            
        } catch (error) {
            console.error('Error creating shifts:', error);
        }
    };
    const handleSelectHalfDay = (option) => {
        
        
        if (option) {
            setHalfDay(option.value);
            console.log(halfDay)
        } else {
            setHalfDay(null);
        }
         
    };
    const handleClose = ()=>{
        resetForm()
    }

    const weekoff = [
        { value: 'Choose', label: 'Choose' },
        { value: 'Monday', label: 'Monday' },
        { value: 'Tuesday', label: 'Tuesday' },
        { value: 'Wednesday', label: 'Wednesday' },
        { value: 'Thursday', label: 'Thursday' },
        { value: 'Friday', label: 'Friday' },
        { value: 'Saturday', label: 'Saturday' },
        { value: 'Sunday', label: 'Sunday' },
    ];
    const halfday = [
        { value: 'Monday', label: 'Monday' , isDisabled: weekOff === 'Monday'},
        { value: 'Tuesday', label: 'Tuesday' , isDisabled: weekOff ==='Tuesday' },
        { value: 'Wednesday', label: 'Wednesday' , isDisabled: weekOff ==='Wednesday' },
        { value: 'Thursday', label: 'Thursday' , isDisabled: weekOff ==='Thursday' },
        { value: 'Friday', label: 'Friday' , isDisabled: weekOff ==='Friday' },
        { value: 'Saturday', label: 'Saturday' , isDisabled: weekOff ==='Saturday' },
        { value: 'Sunday', label: 'Sunday' , isDisabled: weekOff === 'Sunday'},
    ];
   
    const resetForm = () => {
        setShiftName(''); // Reset shift name
        setFromTime(moment('00:00', 'HH:mm')); // Reset from time
        setToTime(moment('00:00', 'HH:mm')); // Reset to time
        setWeekOffOption(null); // Reset week off option
        setSelectedOption(null); // Reset selected option in the dropdown
        statusIsChecked(false); // Reset status checkbox
        setHalfDay(null);
        if (selectRef.current) {
            selectRef.current.clearValue(); // Clear the selected value in the Select component
        }
        setHalfDayFrom(moment('00:00', 'HH:mm'));
        setHalfDayTo(moment('00:00', 'HH:mm'));
        setWeekdaysDefinition([
            { day: 'Monday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
            { day: 'Tuesday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
            { day: 'Wednesday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
            { day: 'Thursday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
            { day: 'Friday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
            { day: 'Saturday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
            { day: 'Sunday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } }
        ]); // Reset weekdays definition
    };
    const showSuccess = () => {
        return Swal.fire({
          title: "Good job!",
          text: "Successfully Saved!",
          icon: "success",
        });
      };
      const showWarning = (str) =>{
        return Swal.fire({
          title: "Error",
          text: str,
          icon: "error",
        });
      }
    return (
        <div>
            {/* Add Shift */}
            <div className="modal fade" id="add-units">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Add New Shift</h4>
                                    </div>
                                    
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form>
                                        <ul
                                            className="nav nav-pills modal-table-tab"
                                            id="pills-tab"
                                            role="tablist"
                                        >
                                           
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div
                                                className="tab-pane fade show active"
                                                id="pills-add-shift-info"
                                                role="tabpanel"
                                                aria-labelledby="pills-add-shift-info-tab"
                                            >
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="input-blocks">
                                                            <label>Shift Name</label>
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                value={shiftName} 
                                                                onChange={(e) => setShiftName(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="input-blocks">
                                                            <label>From</label>
                                                            <div className="form-icon">
                                                            <TimePicker
                                                            value={from}
                                                            className="input-group-text"
                                                            onChange={handleChangeFromTime} 
                                                            defaultValue={moment('00:00', 'HH:mm')} 
                                                            format="HH:mm"
                                                            popupStyle={{ zIndex: 1070 }}
                                                            //open={false}
                                                            style={{ width: "100%",
                                                                boxSizing:"border-box"
                                                             }}
                                                            
                                                          />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="input-blocks">
                                                            <label>To</label>
                                                            <div className="form-icon">
                                                            <TimePicker
                                                            value={to}
                                                            className="input-group-text"
                                                            onChange={handleChangeToTime} 
                                                            defaultValue={moment('00:00', 'HH:mm')} 
                                                            popupStyle={{ zIndex: 1070 }}
                                                            format="HH:mm"
                                                            //open={false}
                                                            style={{ width: "100%",
                                                                boxSizing:"border-box"
                                                             }}
                                                            
                                                          />
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="input-blocks">
                                                            <label>Weekoff</label>
                                                           
                                                            <Select
                                                                className="select"
                                                                options={weekoff}
                                                                placeholder="Newest"
                                                                value={selectedOption}
                                                                onChange={handleChange}
                                                                getOptionLabel={(option) => option.label} // Function to render the label
                                                            />
                                                        </div>
                                                        {/* <h4>Add Halfday</h4> */}
                                                        <div className="input-blocks">
                                                    
                                                            <label>Add a Half Day</label>
                                                               <Select   
                                                               ref={selectRef}                                                       
                                                                className="select"
                                                                options={halfday}
                                                                placeholder="Select the half day"
                                                               onChange={handleSelectHalfDay}
                                                                isClearable
                                                            />
                                                        </div>
                                                        {halfDay && (<div className='row'>
                                                                  <div className="col-lg-6">
                                                                  <div className="input-blocks">
                                                                      <label>From</label>
                                                                      <div className="form-icon">
                                                                      <TimePicker
                                                                      //open={false}
                                                                      value={halfDayFrom}
                                                                      popupStyle={{ zIndex: 1070 }}
                                                                      className="input-group-text"
                                                                      onChange={handleChangeHalfDayFromTime} 
                                                                      defaultValue={moment('00:00', 'HH:mm')} 
                                                                      format="HH:mm"
                                                                      style={{ width: "100%",
                                                                          boxSizing:"border-box"
                                                                       }}
                                                                      
                                                                    />
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                              <div className="col-lg-6">
                                                                  <div className="input-blocks">
                                                                      <label>To</label>
                                                                      <div className="form-icon">
                                                                      <TimePicker
                                                                      //open={false}
                                                                      value={halfDayTo}
                                                                      className="input-group-text"
                                                                      onChange={handleChangeHalfDayToTime} 
                                                                      defaultValue={moment('00:00', 'HH:mm')} 
                                                                      format="HH:mm"
                                                                      popupStyle={{ zIndex: 1070 }}
                                                                      style={{ width: "100%",
                                                                          boxSizing:"border-box"
                                                                       }}
                                                                      
                                                                    />
                                                                          
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                        </div>
                                                    )}
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="modal-table-item">
                                                            {/* <h4>Weekdays Defeniton</h4> */}
                                                            {/* <div className="table-responsive no-pagination"> */}
                                                                {/* <table className="table  datanew">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Days</th>
                                                                           
                                                                        </tr>
                                                                    </thead> */}
                                                                    {/* <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="status-toggle modal-status d-flex align-items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="MondayCheckbox"
                                                                                        className="check"
                                                                                        checked={weekdaysDefinition[0].checked}
                                                                                        onChange={() => handleDayCheckboxChange(0)}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="MondayCheckbox"
                                                                                        className="checktoggle"
                                                                                    />
                                                                                    <span className="status-label ms-2">
                                                                                        Monday
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="status-toggle modal-status d-flex align-items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="TuesdayCheckbox"
                                                                                        className="check"
                                                                                        checked={weekdaysDefinition[1].checked}
                                                                                        onChange={() => handleDayCheckboxChange(1)}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="TuesdayCheckbox"
                                                                                        className="checktoggle"
                                                                                    />
                                                                                    <span className="status-label ms-2">
                                                                                        Tuesday
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="status-toggle modal-status d-flex align-items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="WednesdayCheckbox"
                                                                                        className="check"
                                                                                        checked={weekdaysDefinition[2].checked}
                                                                                        onChange={() => handleDayCheckboxChange(2)}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="WednesdayCheckbox"
                                                                                        className="checktoggle"
                                                                                    />
                                                                                    <span className="status-label ms-2">
                                                                                        Wednesday
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="status-toggle modal-status d-flex align-items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="ThursdayCheckbox"
                                                                                        className="check"
                                                                                        checked={weekdaysDefinition[3].checked}
                                                                                        onChange={() => handleDayCheckboxChange(3)}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="ThursdayCheckbox"
                                                                                        className="checktoggle"
                                                                                    />
                                                                                    <span className="status-label ms-2">
                                                                                        Thursday
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                           
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="status-toggle modal-status d-flex align-items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="FridayCheckbox"
                                                                                        className="check"
                                                                                        checked={weekdaysDefinition[4].checked}
                                                                                        onChange={() => handleDayCheckboxChange(4)}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="FridayCheckbox"
                                                                                        className="checktoggle"
                                                                                    />
                                                                                    <span className="status-label ms-2">
                                                                                        Friday
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="status-toggle modal-status d-flex align-items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="SaturdayCheckbox"
                                                                                        className="check"
                                                                                        checked={weekdaysDefinition[5].checked}
                                                                                        onChange={() => handleDayCheckboxChange(5)}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="SaturdayCheckbox"
                                                                                        className="checktoggle"
                                                                                    />
                                                                                    <span className="status-label ms-2">
                                                                                        Saturday
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="status-toggle modal-status d-flex align-items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="SundayCheckbox"
                                                                                        className="check"
                                                                                        checked={weekdaysDefinition[6].checked}
                                                                                        onChange={() => handleDayCheckboxChange(6)}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="SundayCheckbox"
                                                                                        className="checktoggle"
                                                                                    />
                                                                                    <span className="status-label ms-2">
                                                                                        Sunday
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            
                                                                        </tr>
                                                                    </tbody> */}
                                                                {/* </table> */}
                                                            {/* </div> */}
                                                            
                                                            <div className="input-blocks m-0">
                                                                <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                                    <span className="status-label">Status</span>
                                                                    <input
                                                                        type="checkbox"
                                                                        id="user6"
                                                                        className="check"
                                                                        defaultChecked="true"
                                                                        checked={status}
                                                                        onChange={handleStatusCheckboxChange}
                                                                    />
                                                                    <label
                                                                        htmlFor="user6"
                                                                        className="checktoggle mb-0"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",

                                        }} className="mt-4" >
                                            <button
                                                type="button"
                                                className="btn btn-cancel"
                                                data-bs-dismiss="modal"
                                                onClick={handleClose}
                                            >
                                                Close
                                            </button>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn btn-reset me-2"
                                                    onClick={resetForm}
                                                >
                                                    Reset
                                                </button>
                                                {(!shiftName ||!weekOff || from.isSame(moment('00:00:00', 'HH:mm:ss')) || to.isSame(moment('00:00:00', 'HH:mm:ss')) || (halfDay && (
                                                    halfDayFrom.isSame(moment('00:00:00', 'HH:mm:ss')) || halfDayTo.isSame(moment('00:00:00', 'HH:mm:ss'))
                                                ))) ? (
                                                    <button
                                                        type="button"
                                                        className="btn btn-submit"
                                                        onClick={handleSubmit}

                                                    >
                                                        Save
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="btn btn-submit"
                                                        onClick={handleSubmit}
                                                        data-bs-dismiss="modal"
                                                    >
                                                        Save
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Shift */}
        </div>
    )
}
AddShift.propTypes = {
    onShiftAdded: PropTypes.func.isRequired,
};

export default AddShift;
