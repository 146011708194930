import axios from 'axios';
import Swal from "sweetalert2";
const showWarning = (str) => {
    return Swal.fire({
        title: "Error",
        text: str,
        icon: "error",
    });
}

export const getEmployeeRecordsByDate = async (employeeId, date) => {
    try {
        const response = await axios.get(`/adminAtendances/employee-date`, {
            params: {
                employeeId: employeeId,
                date: date,
            },
        });

        console.log('Employee Records:', response.data);
        return response.data; // Return the fetched data
    } catch (error) {
        console.error('Error fetching employee records:', error);
        throw error; // Optionally rethrow the error for further handling
    }
};

export const updateAttendanceTime = async (attendanceId, recordTime,setLoading) => {
    try {
        setLoading(true)
      const response = await axios.post('/adminAtendances/updateTime', { 
        id: attendanceId,
        time: recordTime
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
        if (response.data) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Time Updated Successfully",
            })
        }else{
            showWarning("Error updating attendance time")
        }
      
      setLoading(false)
      return response.data; // Return the updated record if needed
    } catch (error) {
      console.error('Error updating attendance:', error.response ? error.response.data : error.message);
      throw error; // Re-throw error to handle it further if needed
    }
  };
