import React, { useEffect, useState } from 'react';
import { Table, Select } from 'antd';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Link } from 'react-router-dom';
import { getPayrolls ,getAllPayrolls} from '../../service/ApiServices';
import Swal from 'sweetalert2';
import { Spin } from "antd";
const { Option } = Select;

function Payslip() {
    const currentMonth = new Date().getMonth();
    const [payrollData, setPayrollData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth + 1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState("")
    const [generatePaySlip,setGeneratePaySlip]=useState(false)
    const [initialLoad,setInitialLoad]=useState(true)
    const [loading2, setLoading2] = useState(false);
    const fetchPayrollData = async (page = 1, month = 9, limit = 10) => {
        getPayrolls({
            setCurrentPage,
            limit,
            page,
            month,
            search,
            setLoading,
            setPayrollData,
            setTotalRecords
        })
    };

    const fetchAllPayrollData = async ( ) => {
        getAllPayrolls({
            month:selectedMonth,
            search,
            setLoading2,
            setPayrollData,
            
        })
    };

    const getMonthName = (monthNumber) => {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        return monthNames[monthNumber - 1]; // Subtract 1 because array index starts at 0
    };

    const generatePayslipPDF = (record) => {
        const calculationArray = [
            ['Basic Salary', `Rs. ${record.basic_salary}`],
            ['No Pay Deduction', `Rs. ${record.noPayDeduction}`],
            ['Final Basic Salary', `Rs. ${record.finalBasicSalary}`]
        ]
        if (Array.isArray(record.allowances) && record.allowances.length !== 0) {
            record.allowances.map((item) => {
                calculationArray.push([
                    item.name + " " + new Date().toLocaleDateString(), `Rs. ${item.amount}`
                ])
            })
        }
        calculationArray.push(
            ['Total Allowances', `Rs. ${record.totalAllowanceAmount}`],
            [`Employee Provident Fund (EPF ${record.ept_employee_rate}%)`, `Rs. ${record.calculateEPF}`],
            ['Other Deduction', '']

        )
        if (Array.isArray(record.otherDeductions) && record.otherDeductions.length !== 0) {
            record.otherDeductions.map((item) => {
                if (item.paymentType === "recurring") {
                    calculationArray.push([
                        item.description, `Rs. ${item.installement}`
                    ])
                } else {
                    calculationArray.push([
                        item.description, `Rs. ${item.amount}`
                    ])
                }
            })
        }
        calculationArray.push(
            ['Total of Other Deductions', `Rs. ${record.calculateOtherDeductions}`],
            ['Net Pay', `Rs. ${record.calculateNetPay}`]

        )
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();
        const lineWidth = 170;
        const startX = (pageWidth - lineWidth) / 2;
        const headerY = 20;

        doc.setFontSize(14);
        const schoolName = "OKI INTERNATIONAL SCHOOL";
        const location = "WATTALA";
        const salaryMonth = `Salary for the month of ${getMonthName(selectedMonth)} 2024`;

        doc.text(schoolName, (pageWidth - doc.getTextWidth(schoolName)) / 2, headerY);
        doc.text(location, (pageWidth - doc.getTextWidth(location)) / 2, headerY + 10);
        doc.setFontSize(12);
        doc.text(salaryMonth, (pageWidth - doc.getTextWidth(salaryMonth)) / 2, headerY + 20);

        doc.setFontSize(10);
        doc.autoTable({
            startY: 50,
            head: "",
            body: [
                ['EPF NUMBER', `${record.epf_number}`],
                ['Branch', `${record.branch || 'N/A'}`],
                ['Name', `${record.name}`],
                ['ID Number', ` ${record.employee_id}`]
            ],
            theme: 'plain',
            columnStyles: {
                0: { cellWidth: pageWidth * 0.25 },
            },
        });
        doc.autoTable({
            startY: 90,
            head: "",
            body: calculationArray,
        });

        const footerY = doc.autoTable.previous.finalY + 20;
        doc.line(startX, footerY, startX + lineWidth, footerY);
        doc.autoTable({
            startY: footerY + 5,
            head: "",
            body: [
                ['12% EPF', `Rs. ${record.calculateEPFCompany}`],
                ['20% EPF', `Rs. ${record.totalEPF}`],
                ['03% ETF', `Rs. ${record.calculateETF}`],
            ],//  [`Employee Trust Fund (ETF ${record.etf}%)`, `Rs. ${record.calculateETF}`],
            theme: 'plain',
            columnStyles: {
                0: { cellWidth: pageWidth * 0.64 },
            },
        });

        doc.save(`Payslip_${record.epf_number}_${record.name}.pdf`);
    };



    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
        fetchPayrollData(pagination.current, selectedMonth, pagination.pageSize);
    };

    useEffect(() => {
        if(initialLoad){
            fetchPayrollData(currentPage, selectedMonth, pageSize);
            setInitialLoad(false)
        }
        if(generatePaySlip){
        fetchPayrollData(currentPage, selectedMonth, pageSize);
        setGeneratePaySlip(false)
    }
    }, [currentPage, selectedMonth, pageSize, search,generatePaySlip,initialLoad]);

    const handleMonthChange = (value) => {
        setSelectedMonth(value);
        setCurrentPage(1);
    };

    const handleGeneratePaySlips = () => {
        Swal.fire({
          title: 'Are you sure?',
          text: 'Do you want to generate PaySlips?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, generate!',
          cancelButtonText: 'Cancel'
        }).then((result) => {
          if (result.isConfirmed) {
            setGeneratePaySlip(true);
          }
        });
      };

    const columns = [
        {
            title: 'EPF Number',
            dataIndex: 'epf_number',
            key: 'epf_number',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Basic Salary',
            dataIndex: 'basic_salary',
            key: 'basic_salary',
        },
        {
            title: 'Net Pay',
            dataIndex: 'calculateNetPay',
            key: 'calculateNetPay',
            render:(text,record)=>(
                record.finalBasicSalary?record.calculateNetPay:"----------"
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Link className="me-2 p-2" to="#" 
                    onClick={() => {
                        if(!record.finalBasicSalary){
                            Swal.fire({
                                icon:"error",
                                text:`${record.payeTax} didnt have any attendace to calculate payroll! `,
                                title:"Error"
                            })
                            return
                        }
                        generatePayslipPDF(record)
                    
                    }}
                >
                    <i data-feather="show" className="feather-download"></i>
                </Link>
            ),
        },
    ];

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Payrolls</h4>
                            <h6>Manage Employee payrolls</h6>
                        </div>
                    </div>
                </div>

                <div className="card table-list-card">
                    <div className="card-body">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center", // Use alignItems for vertical alignment
                                gap: "10px",          // Adds space between elements
                                padding: "10px"       // Optional padding
                            }}
                        >
                            <div className="search-set">
                                <div className="search-input" >
                                    <input
                                        type="search"
                                        className="form-control"
                                        placeholder="Search"
                                        value={search}
                                        onChange={(e) => {
                                            setSearch(e.target.value)
                                            fetchPayrollData(currentPage, selectedMonth, pageSize);
                                        }}
                                    />
                                    <Link className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </Link>
                                </div>

                            </div>
                            <div>
                                <Select
                                    value={selectedMonth}
                                    style={{ width: '100%' }}
                                    size={"large"}
                                    onChange={handleMonthChange}
                                >
                                    <Option value={1} disabled={1 > currentMonth + 1}>January</Option>
                                    <Option value={2} disabled={2 > currentMonth + 1}>February</Option>
                                    <Option value={3} disabled={3 > currentMonth + 1}>March</Option>
                                    <Option value={4} disabled={4 > currentMonth + 1}>April</Option>
                                    <Option value={5} disabled={5 > currentMonth + 1}>May</Option>
                                    <Option value={6} disabled={6 > currentMonth + 1}>June</Option>
                                    <Option value={7} disabled={7 > currentMonth + 1}>July</Option>
                                    <Option value={8} disabled={8 > currentMonth + 1}>August</Option>
                                    <Option value={9} disabled={9 > currentMonth + 1}>September</Option>
                                    <Option value={10} disabled={10 > currentMonth + 1}>October</Option>
                                    <Option value={11} disabled={11 > currentMonth + 1}>November</Option>
                                    <Option value={12} disabled={12 > currentMonth + 1}>December</Option>
                                </Select>
                            </div>
                            <div>
                                <button className='btn btn-submit'
                                onClick={handleGeneratePaySlips}
                                >Generate PaySlips</button>
                            </div>
                            <div>
                                <button className='btn btn-submit'
                                    onClick={fetchAllPayrollData}
                                >
                                    {loading2 ? (
                                        <>
                                            <Spin size="small" />{" "}
                                            <span>Please wait...</span> {/* Text to show while loading */}
                                        </>
                                    ) : (
                                        "Generate All Pay Slips"
                                    )}
                                </button>
                            </div>
                        </div>

                        <div className="table-responsive">
                            <Table
                                columns={columns}
                                dataSource={payrollData}
                                rowKey="epf_number"
                                pagination={{
                                    current: currentPage,
                                    pageSize: pageSize,
                                    total: totalRecords,
                                }}
                                loading={loading}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Payslip;
