
import { DatePicker } from 'antd';
import React, { useState, useEffect,useRef } from 'react';
import { createHoliday } from '../../../service/holidayService';
import propTypes from 'prop-types';
import Swal from 'sweetalert2';
import { Spin } from "antd";
import Select from 'react-select';
import dayjs from 'dayjs';
import moment from 'moment';
import { getAllHolidays } from '../../../service/holidayService';
const AddHolidays = ({ onHolidayAdded }) => {
    const [name, setName] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [status, setStatus] = useState(true);
    const [duration, setDuration] = useState(0);
    const [academic, setAcademic] = useState(null);
    const [loading, setLoading] = useState(false);
    const selectRef = useRef(null);
    // setDuration
    // useEffect(() => {
    //     if (startDate && endDate) {
    //         if (endDate.isBefore(startDate, 'day')) {
    //             showWarning('End date cannot be earlier than start date.');
    //             setEndDate(null);
    //             setDuration(0);
    //         } else {
    //             const diff = endDate.diff(startDate, 'days') + 1;
    //             setDuration(diff);
                
    //         }
    //         if (startDate.month() !== endDate.month() || startDate.year() !== endDate.year()) {
    //             showWarning("Start date and end date must be in the same month.");
    //             setEndDate(null);
    //             setDuration(0);
    //         }
            
    //     }else{
    //         setDuration(0)
    //     }
    // }, [startDate, endDate]);


       useEffect(() => {
        if (startDate && endDate) {
            if (endDate.isBefore(startDate, 'day')) {
                showWarning('End date cannot be earlier than start date.');
                setEndDate(null);
                setDuration(0);
            } else {
                const diff = endDate.diff(startDate, 'days') + 1;
                setDuration(diff);
            }
    
            if (startDate.month() !== endDate.month() || startDate.year() !== endDate.year()) {
                showWarning("Start date and end date must be in the same month.");
                setEndDate(null);
                setDuration(0);
            }
        
        } else {
            setDuration(0);
        }
    }, [startDate, endDate]);
    
    

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleStatusChange = (e) => {
        setStatus(e.target.checked);
    };
    const handleSelectChange = (option) => {
        
        
        if (option) {
            setAcademic(option.value);
        } else {
            setAcademic(null);
        }
         
    };
    const options = [
        { value: true, label: 'Academic' },
        { value: false, label: 'Non-Academic' }
    ];
           //////////////////////////
           const search="";
           const [data, setData] = useState([]);
           const [filteredData, setFilteredData] = useState([]);

           const fetchHolidays = async (search = '') => {
               await getAllHolidays({
                   search,
                   setData: setData,
                   
               });
           };

    useEffect(() => {
        const fetchAndFilterHolidays = async () => {
            if (startDate || endDate) {

                fetchHolidays(search);

                let filtered = data;
                if (startDate) {
                    const startMonth = moment(startDate.toDate()).month();
                    filtered = filtered.filter(date =>
                        moment(date.startDate).month() === startMonth
                    );
                }

                if (endDate) {
                    const endMonth = moment(endDate.toDate()).month();
                    filtered = filtered.filter(date =>
                        moment(date.startDate).month() === endMonth
                    );
                }

                setFilteredData(filtered);
            }
            else {
                setFilteredData([])
            }
        };

        fetchAndFilterHolidays();

    }, [startDate, endDate]);


    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (startDate) {
                const start = moment(startDate.toDate());

                filteredData.forEach(date => {

                    if (
                        start.isBetween(date.startDate, date.endDate, null, '[]') ||
                        start.isSame(date.startDate) ||
                        start.isSame(date.endDate)
                    ) {
                        showWarning("Selected start date already exists in Holiday: " + date.name);
                        setStartDate(null)
                        setFilteredData([])
                    }
                });
            }

            if (endDate) {
                const end = moment(endDate.toDate());

                filteredData.forEach(date => {

                    if (
                        end.isBetween(date.startDate, date.endDate, null, '[]') ||
                        end.isSame(date.startDate) ||
                        end.isSame(date.endDate)
                    ) {
                        showWarning("Selected end date already exists in Holiday: " + date.name);
                        setEndDate(null)
                        setFilteredData([])
                    }
                });
            }
        }, 250);

        return () => clearTimeout(timeoutId);
    }, [startDate, endDate, filteredData]);
         

           /////////////////////////

 
    

    const handleSubmit = async () => {
        if (!name || !startDate || !endDate||(academic===null)) {
            showWarning("Please fill in the required fields.");
            return;
        }
      

        const holiday = {
            name,
            startDate,
            endDate,
            status,
            duration,
            academic
        };
        console.log(holiday)

        try {
            console.log('Holiday OBJ', holiday);
            setLoading(true)
            const response = await createHoliday(holiday);
            console.log('Holiday added successfully', response);
          
            onHolidayAdded(holiday);
            handleReset();  
            setLoading(false)
            if(response.status==="success"){
               showSuccess() 
            }

        } catch (error) {
            console.error('Error adding holiday:', error);
        }
    };

    const handleReset = () => {
        setName('');
        setStartDate(null);
        setEndDate(null);
        setStatus(true);
        setDuration(0);
        setAcademic(null)
        //setError('');
        if (selectRef.current) {
            selectRef.current.clearValue(); // Clear the selected value in the Select component
        }
    };
    const showWarning = (str) =>{
        return Swal.fire({
          title: "Error",
          text: str,
          icon: "error",
        });
      }
      const showSuccess = () => {
        return Swal.fire({
          title: "Good job!",
          text: "Successfully Saved!",
          icon: "success",
        });
      };
      const disabledEndDate = (current) => {
        if (!startDate) {
            return current && current.isBefore(dayjs(), 'month'); // No restrictions if startDate is not selected
        }
        // Restrict endDate to the same month and year as startDate
        return (
            current && (
                current.isBefore(startDate, 'day') || 
                current.month() > startDate.month() && current.year() === startDate.year() || 
                current.year() > startDate.year() 
            ));
    };
    const disabledStartDate = (current) => {
        if (!endDate) {
            return  current && current.isBefore(dayjs(), 'month'); 
        }
        return (
            current && 
            (current.isBefore(dayjs(), 'month') || (endDate && current.isAfter(endDate, 'day'))||(endDate && current.isBefore(dayjs(endDate).startOf('month'))))
        );
    };
      
    return (
        <div>
            {/* Add Department */}
            <div className="modal fade" id="add-department">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Add Holiday</h4>
                                    </div>
                                    {/* <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button> */}
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label>Add Holiday</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <div className="input-blocks">
                                                        <label>Select Holiday Type</label>
                                                        <Select
                                                            ref={selectRef}
                                                            className='select'
                                                            options={options}
                                                            placeholder="Select holiday type"
                                                            onChange={handleSelectChange}
                                                            //isClearable
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>Start Date</label>
                                                    <div className="input-groupicon calender-input">
                                                        <DatePicker
                                                            value={startDate}
                                                            onChange={handleStartDateChange}
                                                            type="date"
                                                            className="filterdatepicker"
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholder='20-2-2024'
                                                            getPopupContainer={(trigger) => trigger.parentNode}
                                                            disabledDate={disabledStartDate}
                                                            defaultPickerValue={endDate||dayjs()}
                                                            style={{ width: "100%",
                                                                boxSizing:"border-box"
                                                             }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>End Date</label>
                                                    <div className="input-groupicon calender-input">
                                                        <DatePicker
                                                            value={endDate}
                                                            onChange={handleEndDateChange}
                                                            type="date"
                                                            className="filterdatepicker"
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholder='20-2-2024'
                                                            getPopupContainer={(trigger) => trigger.parentNode}
                                                            disabledDate={disabledEndDate}
                                                            defaultPickerValue={startDate||dayjs()}
                                                            style={{ width: "100%",
                                                                boxSizing:"border-box"
                                                             }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="input-blocks">
                                                    <label>No of Days</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="01"
                                                        value={duration}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                      
                                            <div className="input-blocks m-0 pb-1">
                                                <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                    <span className="status-label">Status</span>
                                                    <input
                                                        type="checkbox"
                                                        id="user5"
                                                        className="check"
                                                        checked={status}
                                                        onChange={handleStatusChange}
                                                    />
                                                    <label htmlFor="user5" className="checktoggle">
                                                        {" "}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                <div style={{
                                         display: "flex",
                                         justifyContent: "space-between",
                                        alignItems: "center",
                                        
                                     }} className="mt-4" >
                                    <button
                                        type="button"
                                        className="btn btn-cancel"
                                        data-bs-dismiss="modal"
                                        onClick={handleReset}
                                        >
                                              Close
                                    </button>
                                <div>
                                    <button
                                                type="button"
                                                className="btn btn-reset me-2"
                                                onClick={handleReset}
                                            >
                                                Reset
                                    </button>
                                    {(!name || !startDate || !endDate||(academic===null))?(
                                    <button
                                                type="button"
                                                className="btn btn-submit"
                                                onClick={handleSubmit}
                                                
                                            >
                                                {loading ? <Spin size="small" /> : "Save"}
                                    </button>
                                    ):(
                                        <button
                                                type="button"
                                                className="btn btn-submit"
                                                onClick={handleSubmit}
                                                data-bs-dismiss="modal"
                                            >
                                                {loading ? <Spin size="small" /> : "Save"}
                                    </button>
                                    )}
                                </div>
                                </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Department */}
        </div>
    );
};

AddHolidays.propTypes = {
    onHolidayAdded: propTypes.func.isRequired
};

export default AddHolidays;
