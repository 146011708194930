import axios from "axios";

import { BASE_URL } from "../environment";

export const getPendingJobs = async () => {
  try {
    const response = await axios.get("/dashboard/pending-jobs");
    console.log("Pending Jobs", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching jobs:", error.message);
    throw error;
  }
};

export const getAllJobs = async () => {
  try {
    const response = await axios.get("/dashboard/all-jobs");
    console.log(response.data, "All Jobs");
    return response.data;
  } catch (error) {
    console.error("Error fetching jobs:", error.message);
    throw error;
  }
};

export const getPendingPayments = async () => {
  try {
    const response = await axios.get("/dashboard/pending-payments");
    console.log(response.data, "total outstanding");
    return response.data;
  } catch (error) {
    console.error("Error fetching jobs:", error.message);
    throw error;
  }
};
export const getPaidPayments = async () => {
  try {
    const response = await axios.get("/dashboard/paid");
    console.log(response.data, "JOB JOB DATA API");
    return response.data;
  } catch (error) {
    console.error("Error fetching jobs:", error.message);
    throw error;
  }
};

export const getAllCounts = async () => {
  try {
    const response = await axios.get(
      `http://${BASE_URL}:8000/api/dashboard/get-counts`
    );
    //console.log("All Counts:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching counts:", error.message);
    throw error;
  }
};

export const getMonthlyCounts = async (year) => {
  try {
    const response = await axios.get(
      `http://${BASE_URL}:8000/api/dashboard/sales-purchase-counts?year=${year}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching montly purchase sales counts:",
      error.message
    );
    throw error;
  }
};
