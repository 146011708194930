import axios from "axios";
import { BASE_URL } from "../environment";

export const getLeavesEmployee = async () => {
  try {
    const response = await axios.get(
      `http://${BASE_URL}:8000/api/employee-leave`
    );
    console.log("Leave Employee Fetched..", response.data.data);
    return response.data.data;
  } catch (error) {
    console.log("Error fetching Leave Employees", error.message);
  }
};

export const getSingleLeaveEmployee = async (id) => {
  try {
    const response = await axios.get(
      `http://${BASE_URL}:8000/api/employee-leave/${id}`
    );
    //console.log("Single Leave Employee Fetched..", response.data.data);
    return response.data.data;
  } catch (error) {
    console.log("Error fetching Single Leave Employees", error.message);
  }
};

export const createLeaveEmployee = async (leaveData) => {
  try {
    const response = await axios.post(
      `http://${BASE_URL}:8000/api/employee-leave`,
      {
        leavetype: leaveData.leavetype,
        leaveDays: leaveData.leaveDays, // Array of dates and days (Full Day / Half Day)
        reason: leaveData.reason,
        startDate: leaveData.startDate,
        endDate: leaveData.endDate,
        approval: leaveData.approval || false,
        userId: leaveData.userId,
        name: leaveData.name,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to create employee leave:", error.message);
    throw error;
  }
};
export const updateLeaveEmployee = async (id, leaveDetails) => {
  try {
    const response = await axios.put(
      `http://${BASE_URL}:8000/api/employee-leave/${id}`,
      leaveDetails
    );
    return response.data;
  } catch (error) {
    console.error("Failed to Update employee leave:", error.message);
    throw error;
  }
};
export const deleteLeaveEmployee = async (id) => {
  try {
    const response = await axios.delete(
      `http://${BASE_URL}:8000/api/employee-leave/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to Update employee leave:", error.message);
    throw error;
  }
};

// Fetch leave type and count
export const fetchLeaveTypeAndCount = async (id) => {
  try {
    const response = await axios.get(
      `http://${BASE_URL}:8000/api/allocate-leave-user/${id}`
    );
    console.log("response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Failed fetching leave allocation data:", error.message);
    throw error;
  }
};

//this for status update in admin leavs
export const updateApprovalStatus = async (id, approval) => {
  try {
    const response = await axios.put(`/employee-leave/approval/${id}`, {
      approval,
    });

    console.log(response.data.message);
    return response.data.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Error updating approval status:",
        error.response.data.message
      );
    } else {
      console.error("Error:", error.message);
    }
  }
};

//for table data // with employee details //adminLeaves
export const getEmployeeLeaveWithEmployee = async () => {
  try {
    const response = await axios.get(`/employee-leave`);
    return response.data;
  } catch (error) {
    console.error("Error fetching employee leaves:", error.message);
    throw error;
  }
};
